import React, { useContext, useEffect, useState } from "react";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import BackArrowIcon from "../../assets/backArrowIcon";
import NotificationContext from "../../context/notification-context";
import Button from "../UI/Button";
import Spinner from "../UI/Spinner";
import classes from "./Form.module.css";
import { isError } from "@tanstack/react-query";

const Form = (props) => {

  const fetcher = useFetcher();
  const navigate = useNavigate();

  const cancelButtonHandler = () => {
    navigate(-1);
  }

  const submitButtonHandler = (event) => {
    if (event.currentTarget.disabled) {
      event.preventDefault();
    }
  }
  return (
    <div className={classes.form}>
      <div className="flex flex-between align-center">
        <h1>{props.title}</h1>
        <Link to={props.urlLink}>
          <BackArrowIcon width="4rem" fColor="var(--m-c)"  />
          
        </Link>
      </div>
      <fetcher.Form
        method={props.method}
        encType={props.encType}
        className={props.className ? props.className : ""}
        onSubmit={props.onSubmit}
      >
        {props.children}
        <div className={`${classes.button} flex flex-center`}>
          {fetcher.state === "idle" && !props.isSubmitting && (
            <>
              <Button
                type="submit"
                background="var(--m-c)"
                text="حفظ"
                color="var(--f-c)"
                disabled={props.disabled}
                onClick={submitButtonHandler}
              />
              <Button
                type="button"
                text="إلغاء"
                color="var(--f-c)"
                background="var(--delete-c)"
                onClick={cancelButtonHandler}

              />
            </>
          )}
          {props.isSubmitting && <Spinner />}
        </div>
      </fetcher.Form>
    </div>
  );
};

export default Form;




import React, { useCallback } from "react";
import { createPortal } from "react-dom";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import Backdrop from "../UI/Backdrop";
import classes from "./ImgEditor.module.css";
import { motion } from "framer-motion";

function ImageEditor({
  imageUrl,
  isImgEditorShown,
  onSave,
  ratio,
  closeHandler,
}) {
  let cropOptions;

  if (ratio === undefined)
    cropOptions = [
      {
        titleKey: "classicTv",
        descriptionKey: "4:1",
        ratio: 4 / 1,

        // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
      },
      {
        titleKey: "classicTv",
        descriptionKey: "5:1",
        ratio: 4.55 / 1,
        // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
      },
      {
        titleKey: "cinemascope",
        descriptionKey: "400:85",
        ratio: 400 / 85,
        // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
      },
    ];

  return (
    <>
      {createPortal(
        <motion.div
          layout
          className={"in-center " + classes.model}
          initial={{ opacity: 0, scale: 0, translate: "-50% -50%" }}
          animate={{ opacity: 1, scale: 1, translate: "-50% -50%" }}
        >
          {isImgEditorShown && (
            <FilerobotImageEditor
              source={imageUrl}
              onSave={(editedImageObject, designState) => {
                onSave(editedImageObject);
              }}
              onClose={closeHandler}
              annotationsCommon={{
                fill: "#ff0000",
              }}
              Text={{ text: "Filerobot..." }}
              Rotate={{ angle: 90, componentType: "slider" }}
              loadableDesignState
              useBackendTranslations={true}
              forceToPngInEllipticalCrop={true}
              defaultSavedImageQuality={0.4}
              defaultSavedImageType={"jpeg"}
              Crop={{
                ratio: ratio,
                noPresets: !!ratio,
                presetsFolders: cropOptions,
                autoResize: true,
              }}
              // onBeforeSave={(imageFileInfo) => {
              //   return imageFileInfo;
              // }}
              tabsIds={[
                TABS.ADJUST,
                TABS.ANNOTATE,
                TABS.WATERMARK,
                TABS.FILTERS,
                TABS.RESIZE,
              ]} // or {['Adjust', 'Annotate', 'Watermark']}
              defaultTabId={TABS.ADJUST} // or 'Annotate'
              defaultToolId={TOOLS.CROP} // or 'Text'
            />
          )}
          {isImgEditorShown &&
            createPortal(
              <Backdrop bc="var(--f-c)" onClick={closeHandler} />,
              document.getElementById("backdrop")
            )}
        </motion.div>,
        document.getElementById("model")
      )}
    </>
  );
}

export default ImageEditor;



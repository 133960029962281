export const INITIAL_PRODUCTS_VALUES = {

  Id: null,
  CategoryId: 0,
  Ammount: 0,
  GenderType: 0,
  Tags: "",
  Price: 0,
  OfferPrice: 0,
  BrandId: 0,
  DeliverTime: 0,
  NameAr: "",
  NameEn: "",
  DescriptionAr: "",
  DescriptionEn: "",
  Url: "",
  Images: [],
  SizeId: [],
  ColorsId: [],
};

export const INITIAL_USER_VALUES = {
  Id: null,
  RoleId: 2,
  UserName: "",
  FirstName: "",
  LastName: "",
  PhoneNumber: "",
  DialCode: "",
  Street: "",
  CityId: 0,
  Address: "",
  ProfilePicture: [],
};

export const INITIAL_ADVERTISMENT_VALUES = {
  Id: null,
  Link: "",
  Type: 0,
  ImageUrl: [],
  ProductIds: [],
};

export const INITIAL_CATEGORY_VALUES = {
  Id: null,
  NameAr: "",
  NameEn: "",
  Image: [],
};

export const INITIAL_GENERIC_VALUES = {
  Id: null,
  nameAr: "",
  nameEn: "",
  image:[],
};

export const INTIIAL_CHANGE_PASSWORD_VALUES = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

import React, { useState } from "react";

const ActionContext = React.createContext({
  id: null,
  setActionId: (id) => {},
  ActionFunction: async (id) => {},
  setActionFunction: (ActionFunction) => {},
  isConfirm: false,
  setIsConfirm: (isConfirm) =>{}
});

export const ActionContextProvider= (props) => {
  
  const [id, setId] = useState(null);
  const [isConfirm,setIsConfirm] = useState(false);
  const [ActionFunction, setActionFunctionction] = useState(async () => {});

  const setActionId = (id) => {
    setId(id);
  };

  const setActionFunction = (ActionFunction) => {
    setActionFunctionction(ActionFunction);
  };

  const intitialValue = {
    id,
    setActionId,
    ActionFunction,
    setActionFunction,
    isConfirm,
    setIsConfirm
  };

  return (
    <ActionContext.Provider value={intitialValue}>
      {props.children}
    </ActionContext.Provider>
  );
};

export default ActionContext;

import apiInstance from "./apiInstance";
import API_CONTROLERS from "../../constant/apiControles";
import API_ROUTES from "../../constant/apiEnd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const GetBrands = async (params) => {
    const search = params?.queryKey[2];
  const response = await apiInstance.get(
    API_CONTROLERS.CP_BASIC + API_ROUTES.BRAND.GET_BRANDS,
    {
      params: {
        pageNumber: params?.queryKey[1],
        query: search,

      },
    }
  );
  return response.data.result;
};

export const setBrand = async (data) => {
  return await apiInstance.post(
    API_CONTROLERS.BRAND + API_ROUTES.BRAND.SET_BRAND,
    data
  );
};

export const getBrandById = async (id) => {
  return await apiInstance.get(
    API_CONTROLERS.CP_BASIC + API_ROUTES.BRAND.GET_BRAND_BY_ID,
    {
      params: {
        id,
      },
    
    }
  );
};

export const deleteBrand = async (id) => {
  return await apiInstance.delete(
    API_CONTROLERS.BRAND + API_ROUTES.BRAND.REMOVE_BRAND,
    {
      params: {
        id,
      },
    }
  );
};

export const useBrandActions = (page, searchQuery) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const previousBrands = queryClient.getQueryData(["brands", page, searchQuery]);

  const isLastBrand =
    previousBrands?.totalPage === 1 && previousBrands?.data.length === 1;

  const useGetBrands = useQuery({
    queryKey: ["brands", page, searchQuery],
    queryFn: GetBrands,
    keepPreviousData: true,
  });

  const useSetBrand = useMutation({
    mutationFn: (newBrand) => setBrand(newBrand),
    onSuccess: (newBrand) => {
      const successMessage =
        "تم" + (id ? " تعديل" : " إضافة") + " الشركة بنجاح";
      queryClient.getDefaultOptions().mutations.onSuccess(successMessage);

      queryClient.invalidateQueries(["brands", page]);
  
    },
    onError: (error, v, rollback) => {
      // rollback();
      queryClient.getDefaultOptions().mutations.onError(error);
    },
  });

  const useDeleteBrand = useMutation({
    mutationFn: (id) => deleteBrand(id),
    onMutate: (deletedId) => {
      if (previousBrands)
        queryClient.setQueryData(["brands", page,searchQuery], (oldBrands) => {
          const newData = {
            ...oldBrands,
            totalPage: isLastBrand ? 0 : oldBrands.totalPage,
            totalDataCount: oldBrands.totalDataCount - 1,
            data: oldBrands.data.filter((brand) => brand.id !== deletedId),
          };
          return newData;
        });
      return () => queryClient.setQueryData(["brands", page,searchQuery], previousBrands);
    },
    onSuccess: () => {
      const successMessage = "تم حذف الشركة بنجاح";
      queryClient.getDefaultOptions().mutations.onSuccess(successMessage);
    },
    onError: (error, variables, rollback) => {
      queryClient.getDefaultOptions().mutations.onError();
      return rollback();
    },
  });

  return {
    useGetBrands,
    useSetBrand,
    useDeleteBrand,
  };
};


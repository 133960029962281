import { useQueryClient } from "@tanstack/react-query";
import { motion, AnimatePresence } from "framer-motion";
import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import ActionContext from "../../context/action-context";
import NotificationContext from "../../context/notification-context";
import Backdrop from "./Backdrop";
import classes from "./GenericModel.module.css";

const GenericModel = (props) => {
  const ActionCtx = useContext(ActionContext);
  const [isLoading, setIsLoading] = useState(false);
  const notificationCtx = useContext(NotificationContext);

  const actionHandler = async (e) => {
    e.stopPropagation();
    if (ActionCtx.id) {
      try {
        setIsLoading(true);
        await ActionCtx.ActionFunction(ActionCtx.id);
        ActionCtx.setActionId(null);
        // notificationCtx.setMessage("تم العملية بنجاح");
        // notificationCtx.setMessageType("success");
      } catch (error) {
        notificationCtx.setMessage(error);
      }
      setIsLoading(false);
      props.cancelFun();
      // queryClient.invalidateQueries();
    }
  };

  const cancelActionHandler = () => {
    if (!isLoading) props.cancelFun();
  };

  // useEffect(() => {
  //   window.addEventListener("keypress", async (e) => {
  //     if (e.key === "Enter") await actionHandler();
  //   });

  //   return () => {
  //     window.removeEventListener("keypress", async(e) => {
  //       if (e.key === "Enter") actionHandler();
  //       return;
  //     });
  //   };
  // }, []);

  return (
    <div
      className={classes.model + " text-center in-center"}
      onClick={cancelActionHandler}
    >
      {isLoading && (
        <AnimatePresence>
          <motion.div
            className={classes.loader + " flex flex-center"}
            animate={{ scale: [0.9, 1, 0.9, 1, 0.9] }}
            transition={{ repeat: Infinity, duration: 10 }}
          >
            <motion.div
              animate={{ scale: [0.5, 1.4, 0.5, 1.4, 0.5] }}
              transition={{ repeat: Infinity, duration: 4 }}
            />
          </motion.div>
        </AnimatePresence>
      )}
      {!isLoading && (
        <motion.button
          onClick={actionHandler}
          whileHover={{
            scale: 1.5,
            backgroundColor: "var(--m-c)",
            color: "var(--bg-white)",
          }}
          tabIndex={0}
        >
          تأكيد
        </motion.button>
      )}
      {ReactDOM.createPortal(
        <Backdrop bc="var(--f-c)" onClick={cancelActionHandler} />,
        document.getElementById("backdrop")
      )}
    </div>
  );
};

export default GenericModel;

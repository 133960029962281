import React, { useState } from "react";
import { motion } from "framer-motion";

const Show = (props) => {
  const [variants, setVariants] = useState("hide");

  const hoverHandler = () => {
    setVariants("show");
  };

  const endHoverHandler = () => {
    setVariants("hide");
  };

  const circleVariants = {
    show: {
      x: ["-1px", "1px"],
      transition: { delay: 0.1,  ease: 'easeIn' },
    },
    hide: {
      x: "0",
    },
  };
  return (
    <motion.svg
      width={props.width}
      height={props.width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="show"
      animate={variants}
      onHoverStart={hoverHandler}
      onHoverEnd={endHoverHandler}
      onClick={props.onClick}
    >
      <path
        d="M21.335 11.4069L22.2682 11.0474L21.335 11.4069ZM21.335 12.5932L20.4018 12.2337L21.335 12.5932ZM2.66492 11.4068L1.73175 11.0474L2.66492 11.4068ZM2.66492 12.5932L1.73175 12.9526L2.66492 12.5932ZM3.5981 11.7663C4.89784 8.39171 8.17084 6 12 6V4C7.31641 4 3.31889 6.92667 1.73175 11.0474L3.5981 11.7663ZM12 6C15.8291 6 19.1021 8.39172 20.4018 11.7663L22.2682 11.0474C20.681 6.92668 16.6835 4 12 4V6ZM20.4018 12.2337C19.1021 15.6083 15.8291 18 12 18V20C16.6835 20 20.681 17.0733 22.2682 12.9526L20.4018 12.2337ZM12 18C8.17084 18 4.89784 15.6083 3.5981 12.2337L1.73175 12.9526C3.31889 17.0733 7.31641 20 12 20V18ZM20.4018 11.7663C20.4597 11.9165 20.4597 12.0835 20.4018 12.2337L22.2682 12.9526C22.5043 12.3396 22.5043 11.6604 22.2682 11.0474L20.4018 11.7663ZM1.73175 11.0474C1.49567 11.6604 1.49567 12.3396 1.73175 12.9526L3.5981 12.2337C3.54022 12.0835 3.54022 11.9165 3.5981 11.7663L1.73175 11.0474Z"
        fill={props.fColor}
      />
      <motion.circle
        variants={circleVariants}
        cx="12"
        cy="12"
        r="3"
        stroke={props.fColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </motion.svg>
  );
};

export default Show;

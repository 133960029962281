import React, { useContext, useEffect, useState } from "react";
import Form from "../../components/Form/Form";
import { useActionData, useLoaderData, useNavigate } from "react-router-dom";
import { getSettings, setSetting } from "../../components/api/setting";
import Input from "../../components/Form/Input";

const Settings = () => {
  const loaderData = useLoaderData();
  const action = useActionData();
  const navigate = useNavigate();

  if (action) navigate(0);

  const settingsData = loaderData.data.result;

  return (
    <>
      <Form method="post" className="flex flex-column" title="تعديل الإعدادات">
        <Input
          id="id"
          text="المعرف"
          type="text"
          defaultValue={settingsData[0]?.id}
          hidden
          readOnly
        />
        <Input
          id="name"
          text="الاسم"
          type="text"
          defaultValue={settingsData[0]?.keyName}
          readOnly
        />
        <Input
          id="price"
          text="القيمة"
          type="number"
          defaultValue={settingsData[0]?.value}
        />
      </Form>
    </>
  );
};

export const loader = () => {
  return getSettings();
};

export const action = async ({ request }) => {
  const formData = await request.formData();

  const data = {
    id: formData.get("id") || 0,
    KeyName: formData.get("name"),
    Value: formData.get("price"),
  };

  if (!data.KeyName || !data.Value) return null;
  return setSetting(data);
};

export default Settings;

import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import CheckIcon from "../../assets/checkIcon";
import ErrorIcon from "../../assets/errorIcon";
import InfoIcon from "../../assets/infoIcon";
import NotificationContext from "../../context/notification-context";
import classes from "./Alert.module.css";

const alertOptions = {
  error: {
    icon: <ErrorIcon width={"2rem"} fColor={"var(--bg-white)"} />,
    class: "error",
  },
  success: {
    icon: <CheckIcon width={"2rem"} fColor={"var(--bg-white"} />,
    class: "success",
  },
  info: {
    icon: <InfoIcon width={"2rem"} fColor={"var(--bg-white)"}></InfoIcon>,
    class: "info",
  },
};
let alertTimeout = 3000;

export const Alert = (props) => {
  const notificationCtx = useContext(NotificationContext);
  const alertTypeClass = alertOptions[props.type].class;
  const [alertMessage, setAlertMessage] = useState(notificationCtx.message);

  useEffect(() => {
    let timer = setTimeout(() => {
      notificationCtx.setMessage(null);
      notificationCtx.setMessageType("error");
    }, alertTimeout);
    if (notificationCtx.message) setAlertMessage(notificationCtx.message);
    return () => {
      clearTimeout(timer);
    };
  }, [notificationCtx, notificationCtx.message]);

  let alertClasses = `${classes.alert} ${classes[alertTypeClass]} `;

  const closeAlertHandler = useCallback(() => {
    notificationCtx.setMessage(null);
  }, [notificationCtx]);

  return (
    <AnimatePresence>
      {props.isOpen && (
        <motion.div
          className={alertClasses + " flex align-center gap-10"}
          onClick={closeAlertHandler}
          style={{ "--duration": props.duration }}
          initial={{ bottom: -50 }}
          animate={{ bottom: 20 }}
          exit={{ bottom: -100 }}
        >
          <span
            // animate={{ rotate: "360deg" }}
            // transition={{
            //   repeat: Infinity,
            //   duration: 4
            // }}
            className="grid place-center"
          >
            {alertOptions[props.type].icon}
          </span>
          <span>{alertMessage}</span>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const showAlert = (message, isOpen, setIsOpen, duration, isSuccess) => {
  return createPortal(
    <Alert
      message={message}
      isSuccess={isSuccess}
      isOpen={isOpen}
      duration={duration}
      setIsOpen={setIsOpen}
    ></Alert>,
    document.getElementById("alert")
  );
};
export default showAlert;

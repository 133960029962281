
import React from "react";
import Input from "../Form/Input";
import "./TableSearch.css";

const TableSearch = React.memo(({ onSearchChange ,searchQuery}) => { 
  return (
    <div className='table-search h-100 flex gap-20 align-center'>
      <label htmlFor="table-search">ابحث:</label>
      <input 
        type="search" 
        id='table-search' 
        className='radius-10' 
        onChange={onSearchChange}
        value={searchQuery}
      />
    </div>
  );
});
export default TableSearch;













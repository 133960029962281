import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./Button";

const AddItem = React.memo((props) => {
  const navigate = useNavigate();

  const addButtonHandler = useCallback(() => {
    navigate(props.url);
  }, []);

  return (
    <div
      style={{ backgroundColor: "var(--bg-white)" }}
      className={"  radius-10 p-1  flex flex-column text-center gap-20"}
    >
      <h1>{props.title}</h1>
      <p>{props.text}</p>
      <Button
        color="var(--b-c)"
        background="var(--m-c)"
        text={props.buttonName}
        onClick={addButtonHandler}
      />
      
    </div>
  );
});

export default AddItem;

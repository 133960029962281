import React,{useState,useCallback} from 'react';


const useDeleteModel = () => {
    const [openDeleteModle,setOpenDeleteModel] = useState(false);

    const showDeleteModelHandler = useCallback(()=> {
        setOpenDeleteModel(true)
      },[])
    
      const hideDeleteModelHandler = useCallback(()=> {
        setOpenDeleteModel(false)
      },[])

      return {
        openDeleteModle,
        showDeleteModelHandler,
        hideDeleteModelHandler
      }



}

export default useDeleteModel;
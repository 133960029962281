import { BASE_URL } from "../components/api/apiInstance";

export const editGenericValues = (newValues, InitialValues) => {
  InitialValues.Id = newValues.id;
  InitialValues.nameAr = newValues.nameAr;
  InitialValues.nameEn = newValues.nameEn;
  InitialValues.image = [ newValues.image];
};
export const editProductValues = (newValues, InitialValues) => {
  InitialValues.Id = newValues.id;
  InitialValues.CategoryId = newValues.categoryId;
  InitialValues.Ammount = newValues.ammount;
  InitialValues.GenderType = newValues.gender;
  InitialValues.Tags = newValues.tags.map((tag) => tag.name).join(" ");
  InitialValues.Price = newValues.price;
  InitialValues.OfferPrice = newValues.priceAfterOffer;
  InitialValues.BrandId = newValues.brandId;
  InitialValues.DeliverTime = +newValues.deliveryDays;
  InitialValues.NameAr = newValues.nameAr;
  InitialValues.NameEn = newValues.nameEn;
  InitialValues.DescriptionAr = newValues.descriptionAr;
  InitialValues.DescriptionEn = newValues.descriptionEn;
  InitialValues.Url = newValues.url;
  InitialValues.Images = newValues.images.map((img) =>  img.url);
  InitialValues.ImagesIds = newValues.images.map((img) => img.id);
  InitialValues.SizeId = newValues.sizes.map((size) => size.id);
  InitialValues.ColorsId = newValues.colors.map((color) => color.id);
};

export const editUserValues = (newValues, InitialValues) => {
  InitialValues.Id = newValues.id;
  InitialValues.RoleId = newValues.roleId;
  InitialValues.FirstName = newValues.firstName;
  InitialValues.LastName = newValues.lastName;
  InitialValues.PhoneNumber = 0 + newValues.phoneNumber;
  InitialValues.Street = newValues.streetName;
  InitialValues.DialCode = newValues.dialCode;
  InitialValues.Username = newValues.userName;
  InitialValues.CityId = newValues.cityId;
  InitialValues.ProfilePicture = [newValues.imageUrl];
  InitialValues.Address = newValues.address;
};

export const editAdvertismentValues = (newValues, InitialValues) => {
  InitialValues.Id = newValues.id;
  InitialValues.Link = newValues.link;
  InitialValues.Type = newValues.type;
  InitialValues.ImageUrl = [newValues.imageUrl];
  InitialValues.ProductIds = newValues.productIds;
};

export const editCategoryValues = (newValues, InitialValues) => {
  InitialValues.Id = newValues.id;
  InitialValues.NameAr = newValues.nameAr;
  InitialValues.NameEn = newValues.nameEn;
  InitialValues.Image = [newValues.imageUrl];
};

export default [
  {
    id: "+963",
    name: "SY +963",
  },
  {
    id: "+93",
    name: "AF +93 ",
  },
  {
    id: "+358",
    name: "AX +358",
  },
  {
    id: "+355",
    name: "AL +355",
  },
  {
    id: "+213",
    name: "DZ +213",
  },
  {
    id: "+1684",
    name: "AS +1684",
  },
  {
    id: "+376",
    name: "AD +376",
  },
  {
    id: "+244",
    name: "AO +244",
  },
  {
    id: "+1264",
    name: "AI +1264 ",
  },
  {
    id: "+672",
    name: "AQ +672",
  },
  {
    id: "+1268",
    name: "AG +1268 ",
  },
  {
    id: "+54",
    name: "AR +54 ",
  },
  {
    id: "+374",
    name: "AM +374",
  },
  {
    id: "+297",
    name: "AW +297",
  },
  {
    id: "+61",
    name: "AU +61 ",
  },
  {
    id: "+43",
    name: "AT +43 ",
  },
  {
    id: "+994",
    name: "AZ +994",
  },
  {
    id: "+1242",
    name: "BS +1242",
  },
  {
    id: "+973",
    name: "BH +973",
  },
  {
    id: "+880",
    name: "BD +880",
  },
  {
    id: "+1246",
    name: "BB +1246",
  },
  {
    id: "+375",
    name: "BY +375",
  },
  {
    id: "+32",
    name: "BE +32",
  },
  {
    id: "+501",
    name: "BZ +501",
  },
  {
    id: "+229",
    name: "BJ +229",
  },
  {
    id: "+1441",
    name: "BM +1441 ",
  },
  {
    id: "+975",
    name: "BT +975",
  },
  {
    id: "+591",
    name: "BO +591",
  },
  {
    id: "+387",
    name: "BA +387",
  },
  {
    id: "+267",
    name: "BW +267",
  },
  {
    id: "+55",
    name: "BR +55 ",
  },
  {
    id: "+246",
    name: "IO +246",
  },
  {
    id: "+673",
    name: "BN +673",
  },
  {
    id: "+359",
    name: "BG +359",
  },
  {
    id: "+226",
    name: "BF +226",
  },
  {
    id: "+257",
    name: "BI +257",
  },
  {
    id: "+855",
    name: "KH +855",
  },
  {
    id: "+237",
    name: "CM +237",
  },
  {
    id: "+1",
    name: "CA +1 ",
  },
  {
    id: "+238",
    name: "CV +238",
  },
  {
    id: "+345",
    name: "KY +345",
  },
  {
    id: "+236",
    name: "CF +236",
  },
  {
    id: "+235",
    name: "TD +235",
  },
  {
    id: "+56",
    name: "CL +56 ",
  },
  {
    id: "+86",
    name: "CN +86 ",
  },
  {
    id: "+61",
    name: "CX +61 ",
  },
  {
    id: "+61",
    name: "CC +61 ",
  },
  {
    id: "+57",
    name: "CO +57 ",
  },
  {
    id: "+269",
    name: "KM +269",
  },
  {
    id: "+242",
    name: "CG +242",
  },
  {
    id: "+243",
    name: "CD +243",
  },
  {
    id: "+682",
    name: "CK +682",
  },
  {
    id: "+506",
    name: "CR +506",
  },
  {
    id: "+225",
    name: "CI +225",
  },
  {
    id: "+385",
    name: "HR +385",
  },
  {
    id: "+53",
    name: "CU +53",
  },
  {
    id: "+357",
    name: "CY +357",
  },
  {
    id: "+420",
    name: "CZ +420",
  },
  {
    id: "+45",
    name: "DK +45",
  },
  {
    id: "+253",
    name: "DJ +253",
  },
  {
    id: "+1767",
    name: "DM +1767",
  },
  {
    id: "+1849",
    name: "DO +1849",
  },
  {
    id: "+593",
    name: "EC +593",
  },
  {
    id: "+20",
    name: "EG +20 ",
  },
  {
    id: "+503",
    name: "SV +503",
  },
  {
    id: "+240",
    name: "GQ +240",
  },
  {
    id: "+291",
    name: "ER +291",
  },
  {
    id: "+372",
    name: "EE +372",
  },
  {
    id: "+251",
    name: "ET +251",
  },
  {
    id: "+500",
    name: "FK +500",
  },
  {
    id: "+298",
    name: "FO +298",
  },
  {
    id: "+679",
    name: "FJ +679",
  },
  {
    id: "+358",
    name: "FI +358",
  },
  {
    id: "+33",
    name: "FR +33",
  },
  {
    id: "+594",
    name: "GF +594",
  },
  {
    id: "+689",
    name: "PF +689",
  },
  {
    id: "+241",
    name: "GA +241",
  },
  {
    id: "+220",
    name: "GM +220",
  },
  {
    id: "+995",
    name: "GE +995",
  },
  {
    id: "+49",
    name: "DE +49 ",
  },
  {
    id: "+233",
    name: "GH +233",
  },
  {
    id: "+350",
    name: "GI +350",
  },
  {
    id: "+30",
    name: "GR +30 ",
  },
  {
    id: "+299",
    name: "GL +299",
  },
  {
    id: "+1473",
    name: "GD +1473",
  },
  {
    id: "+590",
    name: "GP +590",
  },
  {
    id: "+1671",
    name: "GU +1671",
  },
  {
    id: "+502",
    name: "GT +502",
  },
  {
    id: "+44",
    name: "GG +44 ",
  },
  {
    id: "+224",
    name: "GN +224",
  },
  {
    id: "+245",
    name: "GW +245",
  },
  {
    id: "+595",
    name: "GY +595",
  },
  {
    id: "+509",
    name: "HT +509",
  },
  {
    id: "+379",
    name: "VA +379",
  },
  {
    id: "+504",
    name: "HN +504",
  },
  {
    id: "+852",
    name: "HK +852",
  },
  {
    id: "+36",
    name: "HU +36 ",
  },
  {
    id: "+354",
    name: "IS +354",
  },
  {
    id: "+91",
    name: "IN +91 ",
  },
  {
    id: "+62",
    name: "ID +62 ",
  },
  
  { id: "+98", name: "IR +98 " },
  {
    id: "+964",
    name: "IQ +964",
  },
  {
    id: "+353",
    name: "IE +353",
  },
  {
    id: "+44",
    name: "IM +44",
  },
  {
    id: "+972",
    name: "IL +972",
  },
  {
    id: "+39",
    name: "IT+39 ",
  },
  {
    id: "+1876",
    name: "JM +1876",
  },
  {
    id: "+81",
    name: "JP +81",
  },
  {
    id: "+44",
    name: "JE +44",
  },
  {
    id: "+962",
    name: "JO +962",
  },
  {
    id: "+77",
    name: "KZ +77",
  },
  {
    id: "+254",
    name: "KE +254",
  },
  {
    id: "+686",
    name: "KI +686",
  },
  {
    id: "+850",
    name: "KP +850",
  },
  {
    id: "+82",
    name: "KR +82",
  },
  {
    id: "+965",
    name: "KW +965",
  },
  {
    id: "+996",
    name: "KG +996",
  },
  {
    id: "+856",
    name: "LA +856",
  },
  {
    id: "+371",
    name: "LV +371",
  },
  {
    id: "+961",
    name: "LB +961",
  },
  {
    id: "+266",
    name: "LS +266",
  },
  {
    id: "+231",
    name: "LR +231",
  },
  {
    id: "+218",
    name: "LY +218",
  },
  {
    id: "+423",
    name: "LI +423",
  },
  {
    id: "+370",
    name: "LT +370",
  },
  {
    id: "+352",
    name: "LU +352",
  },
  {
    id: "+853",
    name: "MO +853",
  },
  {
    id: "+389",
    name: "MK +389",
  },
  {
    id: "+261",
    name: "MG +261",
  },
  {
    id: "+265",
    name: "MW +265",
  },
  {
    id: "+60",
    name: "MY +60",
  },
  {
    id: "+960",
    name: "MV +960",
  },
  {
    id: "+223",
    name: "ML +223",
  },
  {
    id: "+356",
    name: "MT +356",
  },
  {
    id: "+692",
    name: "MH +692",
  },
  {
    id: "+596",
    name: "MQ +596",
  },
  {
    id: "+222",
    name: "MR +222",
  },
  {
    id: "+230",
    name: "MU +230",
  },
  {
    id: "+262",
    name: "YT +262",
  },
  {
    id: "+52",
    name: "MX +52",
  },
  {
    id: "+691",
    name: "FM +691",
  },
  {
    id: "+373",
    name: "MD +373",
  },
  {
    id: "+377",
    name: "MC +377",
  },
  {
    id: "+976",
    name: "MN +976",
  },
  {
    id: "+382",
    name: "ME +382",
  },
  {
    id: "+1664",
    name: "MS +1664",
  },
  {
    id: "+212",
    name: "MA +212",
  },
  {
    id: "+258",
    name: "MZ +258",
  },
  {
    id: "+95",
    name: "MM +95",
  },
  {
    id: "+264",
    name: "NA +264",
  },
  {
    id: "+674",
    name: "NR +674",
  },
  {
    id: "+977",
    name: "NP +977",
  },
  {
    id: "+31",
    name: "NL +31",
  },
  {
    id: "+599",
    name: "AN +599",
  },
  {
    id: "+687",
    name: "NC +687",
  },
  {
    id: "+64",
    name: "NZ +64",
  },
  {
    id: "+505",
    name: "NI +505",
  },
  {
    id: "+227",
    name: "NE +227",
  },
  {
    id: "+234",
    name: "NG +234",
  },
  {
    id: "+683",
    name: "NU +683",
  },
  {
    id: "+672",
    name: "NF +672",
  },
  {
    id: "+1670",
    name: "MP +1670",
  },
  {
    id: "+47",
    name: "NO +47",
  },
  {
    id: "+968",
    name: "OM +968",
  },
  {
    id: "+92",
    name: "PK +92",
  },
  {
    id: "+680",
    name: "PW +680",
  },
  {
    id: "+970",
    name: "PS +970",
  },
  {
    id: "+507",
    name: "PA +507",
  },
  {
    id: "+675",
    name: "PG +675",
  },
  {
    id: "+595",
    name: "PY +595",
  },
  {
    id: "+51",
    name: "PE +51",
  },
  {
    id: "+63",
    name: "PH +63",
  },
  {
    id: "+872",
    name: "PN +872",
  },
  {
    id: "+48",
    name: "PL +48",
  },
  {
    id: "+351",
    name: "PT +351",
  },
  {
    id: "+1939",
    name: "PR +1939",
  },
  {
    id: "+974",
    name: "QA +974",
  },
  {
    id: "+40",
    name: "RO+40 ",
  },
  {
    id: "+7",
    name: "R+7U ",
  },
  {
    id: "+250",
    name: "RW +250",
  },
  {
    id: "+262",
    name: "RE +262",
  },
  {
    id: "+590",
    name: "BL +590",
  },
  {
    id: "+290",
    name: "SH +290",
  },
  {
    id: "+1869",
    name: "KN +1869",
  },
  {
    id: "+1758",
    name: "LC +1758",
  },
  {
    id: "+590",
    name: "MF +590",
  },
  {
    id: "+508",
    name: "PM +508",
  },
  {
    id: "+1784",
    name: "VC +1784",
  },
  {
    id: "+685",
    name: "WS +685",
  },
  {
    id: "+378",
    name: "SM +378",
  },
  {
    id: "+239",
    name: "ST +239",
  },
  {
    id: "+966",
    name: "SA +966",
  },
  {
    id: "+221",
    name: "SN +221",
  },
  {
    id: "+381",
    name: "RS +381",
  },
  {
    id: "+248",
    name: "SC +248",
  },
  {
    id: "+232",
    name: "SL +232",
  },
  {
    id: "+65",
    name: "SG +65 ",
  },
  {
    id: "+421",
    name: "SK +421",
  },
  {
    id: "+386",
    name: "SI +386",
  },
  {
    id: "+677",
    name: "SB +677",
  },
  {
    id: "+252",
    name: "SO +252",
  },
  {
    id: "+27",
    name: "ZA +27 ",
  },
  {
    id: "+211",
    name: "SS +211",
  },
  {
    id: "+500",
    name: "GS +500",
  },
  {
    id: "+34",
    name: "ES +34",
  },
  {
    id: "+94",
    name: "LK +94",
  },
  {
    id: "+249",
    name: "SD +249",
  },
  {
    id: "+597",
    name: "SR +597",
  },
  {
    id: "+47",
    name: "SJ +47",
  },
  {
    id: "+268",
    name: "SZ +268",
  },
  {
    id: "+46",
    name: "SE +46",
  },
  {
    id: "+41",
    name: "CH +41",
  },
  
  {
    id: "+886",
    name: "TW +886",
  },
  {
    id: "+992",
    name: "TJ +992",
  },
  {
    id: "+255",
    name: "TZ +255",
  },
  {
    id: "+66",
    name: "TH +66",
  },
  {
    id: "+670",
    name: "TL +670",
  },
  {
    id: "+228",
    name: "TG +228",
  },
  {
    id: "+690",
    name: "TK +690",
  },
  {
    id: "+676",
    name: "TO +676",
  },
  {
    id: "+1868",
    name: "TT +1868",
  },
  {
    id: "+216",
    name: "TN +216",
  },
  {
    id: "+90",
    name: "TR +90",
  },
  {
    id: "+993",
    name: "TM +993",
  },
  {
    id: "+1649",
    name: "TC +1649",
  },
  {
    id: "+688",
    name: "TV +688",
  },
  {
    id: "+256",
    name: "UG +256",
  },
  {
    id: "+380",
    name: "UA +380",
  },
  {
    id: "+971",
    name: "AE +971",
  },
  {
    id: "+44",
    name: "GB +44",
  },
  {
    id: "+1",
    name: "US +1",
  },
  {
    id: "+598",
    name: "UY +598",
  },
  {
    id: "+998",
    name: "UZ +998",
  },
  {
    id: "+678",
    name: "VU +678",
  },
  {
    id: "+58",
    name: "VE +58",
  },
  {
    id: "+84",
    name: "VN +84 ",
  },
  {
    id: "+1284",
    name: "VG +1284",
  },
  {
    id: "+1340",
    name: "VI +1340",
  },
  {
    id: "+681",
    name: "WF +681",
  },
  {
    id: "+967",
    name: "YE +967",
  },
  {
    id: "+260",
    name: "ZM +260",
  },
  {
    id: "+263",
    name: "ZW +263",
  },
];

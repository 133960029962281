import React from "react";
import { useCallback } from "react";
import { useState } from "react";
import Hidden from "../../assets/Hidden";
import Show from "../../assets/Show";
import "./Input.css";

const Input = React.memo((props) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputError = props.touched && props.error;

  const togglePasswordHandler = useCallback(() => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  }, []);

  const controlerClassNames =
    "control grid gap-10 " +
    props.className +
    (inputError ? " error " : "") +
    (props.type === "password" ? "" : "");

  if (props.hidden)
    return (
      <input
        id={props.id}
        name={props.id}
        placeholder={props.placeholder ? props.placeholder : ""}
        aira-label={props.id}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        defaultValue={props.defaultValue}
        hidden
      />
    );

  return (
    <div className={controlerClassNames}>
      <label
        htmlFor={props.id}
        data-type={props.type}
        className={!props.isRequire ? "require" : ""}
      >
        {props.text}
      </label>
      <input
        name={props.id}
        placeholder={props.placeholder ? props.placeholder : ""}
        aira-label={props.id}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        defaultValue={props.defaultValue}
        type={
          props.type === "password"
            ? showPassword
              ? "text"
              : "password"
            : props.type
        }
      />
      {props.type === "password" && (
        <button
          type="button"
          className="show-password"
          onClick={togglePasswordHandler}
          aria-label={"show-password"}
        >
          {showPassword ? (
            <Hidden width={"1.5rem"} fColor={"var(--m-c)"} />
          ) : (
            <Show width={"1.5rem"} fColor={"var(--m-c)"} />
          )}
        </button>
      )}
      {inputError && <span className="error">{props.error}</span>}
    </div>
  );
});

export default Input;

import apiInstance from "./apiInstance";
import API_ROUTES from "../../constant/apiEnd";
import API_CONTROLES from "../../constant/apiControles";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const SetCategory = async (categoryData) => {
  const response = apiInstance.post(
    API_CONTROLES.CATEGORY + API_ROUTES.CATEGORY.SET_CATEGORY,
    categoryData,

    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response;
};

export const GetCategories = async ({ page, enablePagination }) => {
  const response = await apiInstance.get(
    API_CONTROLES.CATEGORY + API_ROUTES.CATEGORY.GET_CATEGORIES,
    {
      params: {
        page,
        enablePagination
      }
    }
  );
  return response.data.result;
};

export const GetCategory = async (id) => {
  const response = apiInstance.get(
    API_CONTROLES.CP_BASIC + API_ROUTES.CATEGORY.GET_CATEGORY,
    {
      params: {
        id,
      },
    }
  );
  return response;
};

export const DeleteCategory = async (id) => {
  const response = apiInstance.delete(
    API_CONTROLES.CATEGORY + API_ROUTES.CATEGORY.REMOVE_CATEGORY,
    {
      params: {
        id,
      },
    }
  );
  return response;
};

export const useGetCategories = ({ page, enablePagination }) => {
  return useQuery(["categories", page], () => GetCategories({ page, enablePagination }));
};

export const useSetCategory = () => {
  const queryClient = useQueryClient();
  const previousCategories = queryClient.getQueryData(["categories"]);
  const { id } = useParams();
  return useMutation({
    mutationFn: (newCategory) => SetCategory(newCategory),
    onSuccess: async (newCategory) => {
      const successMessage = "تم" + (id ? " تعديل" : " إضافة") + " الصنف بنجاح";
      queryClient.invalidateQueries(["categories"]);
      queryClient.getDefaultOptions().mutations.onSuccess(successMessage);
    },
  });
};

export const useDeleteCategory = () => {
  const queryClient = useQueryClient();
  const previousCategories = queryClient.getQueryData(["categories"]);

  return useMutation({
    mutationFn: (id) => DeleteCategory(id),
    onMutate: (id) => {
      const previousCategories = queryClient.getQueryData(["categories"]);
      queryClient.setQueryData(["categories"], (oldCategories) =>
        oldCategories?.filter((category) => category.id !== id)
      );
      return () => queryClient.setQueryData(["categories"], previousCategories);
    },
    onSuccess: () =>
    {
      queryClient.getDefaultOptions().mutations.onSuccess("تم حذف الصنف بنجاح")
      queryClient.invalidateQueries({ queryKey: "categories" })
    },
    onError: (error, variables, rollback) => {
      rollback();
      queryClient.getDefaultOptions().mutations.onError(error);
    },
  });
};

import apiInstance from "./apiInstance";
import API_CONTROLERS from "../../constant/apiControles";
import API_ROUTES from "../../constant/apiEnd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const setSize = async (data) => {
  const response = apiInstance.post(
    API_CONTROLERS.SIZE + API_ROUTES.SIZE.SET_SIZE,
    {
      ...data,
    }
  );
  return response;
};

export const getSizes = async (params) => {
  const  search = params?.queryKey[1];

  const response = await apiInstance.get(
    API_CONTROLERS.CP_BASIC + API_ROUTES.SIZE.GET_SIZES, {
      params: {
        query: search,
      },
    }
  );
  console.log(response.data.result);
  return response.data.result;
};

export const getSizeById = async (id) => {
  const response = apiInstance.get(
    API_CONTROLERS.CP_BASIC + API_ROUTES.SIZE.GET_SIZE_BY_ID,
    {
      params: {
        id,
      },
    }
  );

  return response;
};

export const deleteSize = async (id) => {
  const response = apiInstance.delete(
    API_CONTROLERS.SIZE + API_ROUTES.SIZE.REMOVE_SIZE,
    {
      params: {
        id,
      },
    }
  );

  return response;
};

export const useActionSize = (searchQuery) => {
  const queryClient = useQueryClient();
  const previousSizes = queryClient.getQueryData(["sizes",searchQuery]);
  const { id } = useParams();

  const useGetSizes = useQuery({
    queryKey: ["sizes",searchQuery],
    queryFn: getSizes,
    keepPreviousData: true,
  });

 

  const useSetSize = useMutation({
    mutationFn: (newSize) => setSize(newSize),
    onMutate: async (newSize) => {
      await queryClient.cancelQueries(["sizes", searchQuery]);
      const previousSizes = queryClient.getQueryData(["sizes", searchQuery]);
      queryClient.setQueryData(["sizes", searchQuery], (oldSizes) => [
        ...oldSizes,
        newSize,
      ]);
      return { previousSizes };
    },
    onSuccess: () => {
      const successMessage = "تم إضافة القياس بنجاح";
      queryClient.invalidateQueries(["sizes", searchQuery]);
      queryClient.getDefaultOptions().mutations.onSuccess(successMessage);
    },
    onError: (error, _, context) => {
      if (context?.previousSizes) {
        queryClient.setQueryData(["sizes", searchQuery], context.previousSizes);
      }
      queryClient.getDefaultOptions().mutations.onError(error);
    },
  });




  const useDeleteSize = useMutation({
    mutationFn: (id) => deleteSize(id),
    onMutate: async (id) => {
      await queryClient.cancelQueries(["sizes", searchQuery]);
      const previousSizes = queryClient.getQueryData(["sizes", searchQuery]);
      queryClient.setQueryData(["sizes", searchQuery], (oldSizes) =>
        oldSizes.filter((oldSize) => oldSize.id !== id)
      );
      return { previousSizes };
    },
    onSuccess: () => {
      queryClient.getDefaultOptions().mutations.onSuccess("تم حذف القياس بنجاح");
    },
    onError: (error, _, context) => {
      if (context?.previousSizes) {
        queryClient.setQueryData(["sizes", searchQuery], context.previousSizes);
      }
      queryClient.getDefaultOptions().mutations.onError(error);
    },
  });

  return {
    useGetSizes,
    useSetSize,
    useDeleteSize,
  };
};



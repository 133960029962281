import React, { useState } from "react";

const PaginationContext = React.createContext({
  productPage: 0,
  setProductPage: () => {},
  userPage: 0,
  setUserPage: () => {},
  rejectedOrdersPage: 0,
  setRejectedOrdersPage: () => {},
  confirmOrdersPage: 0,
  setConfirmOrdersPage: () => {},
  pendingOrderPage: 0,
  setPendingOrderPage: () => {},
  companyPage: 0,
  setCompanyPage: () => { },
  categoryPage: 0,
  setCategoryPage: () => {},
  CityPage: 0,
  setCityPage: () => {},
});

export const PaginationContextProvider = (props) => {
  const [productPage, setProductPage] = useState(0);
  const [userPage, setUserPage] = useState(0);
  const [rejectedOrdersPage, setRejectedOrdersPage] = useState(0);
  const [confirmOrdersPage, setConfirmOrdersPage] = useState(0);
  const [pendingOrderPage, setPendingOrderPage] = useState(0);
  const [companyPage, setCompanyPage] = useState(0);
  const [categoryPage, setCategoryPage] = useState(0);
  const [CityPage, setCityPage] = useState(0);


  const initialValues = {
    productPage,
    setProductPage,
    userPage,
    setUserPage,
    rejectedOrdersPage,
    setRejectedOrdersPage,
    confirmOrdersPage,
    setConfirmOrdersPage,
    pendingOrderPage,
    setPendingOrderPage,
    companyPage,
    setCompanyPage,
    categoryPage,
    setCategoryPage,
    CityPage,
    setCityPage,

  };

  return (
    <PaginationContext.Provider value={initialValues}>
      {props.children}
    </PaginationContext.Provider>
  );
};

export default PaginationContext
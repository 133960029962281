const ACCOUNT = {
  SET_USER: '/SetUser',
  LOGIN_USER: "/LogIn",
  GET_ACCOUNTS: "/GetAccounts",
  GET_ACCOUNT_BY_PHONE: "/GetAccountByPhone",
  GET_ACCOUNT: '/GetCpAccount',
  BLOCK_ACCOUNT: "/BlockAccount",
  CHANGE_PASSWORD: '/ChangePassword'
};

const ADVERTISMENT = {
  SET_ADVERTISMENT: "/SetAdvertisment",
  GET_ADVERTISMENT: "/GetAdvertisment",
  GET_ADVERTISMENT_TYPE: "/GetAdvertismentByType",
  REMOVE_ADVERTISMENT: "/RemoveAdvertisment",
};

const BRAND = {
  GET_BRANDS: "/CPBrands",
  GET_BRAND_BY_ID: "/CPBrandById",
  SET_BRAND: "/SetBrand",
  REMOVE_BRAND: "/RemoveBrand",
};

const CATEGORY = {
  GET_CATEGORIES: "/GetCategories",
  GET_CATEGORY: "/CPCategoryById",
  SET_CATEGORY: "/SetCategory",
  REMOVE_CATEGORY: "/RemoveCategory",
};

const COLOR = {
  GET_COLORS: "/CPColors",
  GET_COLOR_BY_ID: "/CPColorById",
  SET_COLOR: "/SetColor",
  REMOVE_COLOR: "/RemoveColor",
};


// const OFFER = {
//   GET_OFFERS: "/getOffers",
//   ACTION_OFFER: "/actionOffer",
//   REMOVE_OFFER: "/removeOffer",
// };

const ORDER = {
  CONFIRM_ORDER: "/ConfirmOrder",
  REJECT_ORDER: "/RejectOrder",
  GET_PENDING_ORDERS: "/GetPendingOrders",
  GET_REJECTED_ORDERS: "/GetRejectedOrders",
  GET_CONFIRMED_CRDERS: '/GetConfirmedOrders',
  DELETE_ORDER: '/DeleteOrder',
  GET_ORDER: '/GetOrderById'

};

const PRODUCT = {
  SET_PRODUCT: "/SetProduct",
  GET_CATEGORY_PRODUCTS: "/GetCategoryProducts",
  GET_TRENDING_PRODUCTS: "/GetTrendingProducts",
  GET_LATEST_PRODUCTS: "/CPProduct",
  DELETE_PRODUCT: '/DeleteProduct',
  DELETE_PRODUCT_FILE: '/DeleteFile',
  GET_PRODCUT: '/GetCpProduct'
};

const SETTING = {
  SET_SETTING: "/SetSetting",
  GET_SETTINGS: "/GetSettings",
  GET_SETTING: "/GetSetting",
};

const CITY = {
  SET_CITY: '/setCity',
  GET_CITIES: '/CPCities',
  GET_CITY: '/GetCity',
  REMOVE_CITY: '/RemoveCity'
}

const SIZE = {
  SET_SIZE: '/SetSize',
  GET_SIZES: '/CPSizes',
  GET_SIZE_BY_ID: '/CPSizeById',
  REMOVE_SIZE: '/RemoveSize',
}

const API_ROUTES = {
  ACCOUNT,
  ADVERTISMENT,
  ORDER,
  BRAND,
  CATEGORY,
  COLOR,
  PRODUCT,
  SETTING,
  CITY,
  SIZE
};

export default API_ROUTES;

import React from "react";

const DeleteIcon = (props) => {
  return (
    <svg
      width={props.width}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      style={{ cursor: "pointer",...props['style'] }}
      className="delete-icon"
    >
      <path
        d="M10 12V17"
        stroke={props.fColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 12V17"
        stroke={props.fColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 7H20"
        stroke={props.fColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="cover"
      />
      <path
        d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10"
        stroke={props.fColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
        stroke={props.fColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="cover"
      />
    </svg>
  );
};

export default DeleteIcon;

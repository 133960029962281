
import { useIsFetching } from "@tanstack/react-query";
import React, { useContext } from "react";
import { useHref, useLocation, useNavigate } from "react-router-dom";
import CheckIcon from "../../assets/checkIcon";
import DeleteIcon from "../../assets/deleteIcon";
import EditIcon from "../../assets/editIcon";
import Show from "../../assets/Show";
import ActionContext from "../../context/action-context";
import { getArrayWithSpecialProp } from "../../helpers/helper";
import useDeleteModel from "../../hooks/use-delete";
import GenericModel from "./GenericModel";
import "./Table.css";
const Table = (props) => {
  const ActionCtx = useContext(ActionContext);
  const navigate = useNavigate();
  const location = useLocation();
  const isFetching = useIsFetching();
  const href = useHref();
  const { openDeleteModle, showDeleteModelHandler, hideDeleteModelHandler } =
    useDeleteModel();
  const tdClasses = "flex-100 flex flex-center";
  let tableData = getArrayWithSpecialProp(props.td, props.propsTable);
  const deleteButtonHandler = (id) => {
    showDeleteModelHandler();
    ActionCtx.setIsConfirm(false);
    ActionCtx.setActionId(id);
  };
  const editButtonHandler = (data, id) => {
    if (!props.isActionConfirm) navigate(location.pathname + "/" + id, {});
    showDeleteModelHandler();
    ActionCtx.setIsConfirm(true);
    ActionCtx.setActionId(data);
  };
  const showButtonHandler = (id) => {
    ActionCtx.setIsConfirm(false);
    ActionCtx.setActionId(id);
    navigate("/alpha/show-order/" + id, { state: { previousUrl: href } });
  };
  return (
    <>
      {openDeleteModle && <GenericModel cancelFun={hideDeleteModelHandler} />}
      <div className="table">
        <div className="flex flex-between gap-20 header">
          <h1>{props.title}</h1>
          <div className="flex-g1">
            {props.children[0]}
          </div>
        </div>
        {props.td.length === 0 && isFetching === 0 ? (
          <h2 className="text-center">لا يوجد بيانات لعرضها</h2>
        ) : (
          <table className="flex flex-column radius-10">
            <thead>
              <tr className="flex flex-between ">
                {props.th.map((h) => (
                  <th
                    key={Math.random().toString()}
                    scope="col"
                    className="flex-100 place-center"
                  >
                    {h}
                  </th>
                ))}
                <th
                  key={Math.random().toString()}
                  scope="col"
                  className="flex-100 place-center"
                >
                  التحكم
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr
                  key={Math.random().toString()}
                  className="flex flex-between align-center"
                >
                  {row.map((d, i) => {
                    if (i !== 0 || props.showId) {
                      const isImage = typeof d === "string" && /\.(jpg|jpeg|png|gif)$/i.test(d);
                      return (
                        <td key={Math.random().toString()} className={tdClasses}>
                          {isImage ? (
                            <img src={d} alt="table content" style={{ width: "50px", height: "50px" }} />
                          ) : (
                            d || "غير معرف"
                          )}
                        </td>
                      );
                    }
                  })}
                  <td className={tdClasses}>
                    <div className="flex gap-10">
                      {props.isNeedShowDetail && (
                        <Show
                          width="1.6rem"
                          fColor="var(--show-c)"
                          onClick={showButtonHandler.bind({}, row[0])}
                        />
                      )}
                      {props.isActionConfirm && !props.withoutConfirm && (
                        <CheckIcon
                          width={"1.6rem"}
                          fColor={"var(--edit-c)"}
                          onClick={editButtonHandler.bind({}, row[0])}
                        />
                      )}
                      {!props.isActionConfirm && !props.withoutEdit && (
                        <EditIcon
                          width="1.6rem"
                          fColor="var(--edit-c)"
                          onClick={editButtonHandler.bind(
                            {},
                            props.td[index],
                            row[0]
                          )}
                        />
                      )}
                      {!props.withoutDelete && (
                        <DeleteIcon
                          width="1.6rem"
                          fColor="var(--delete-c)"
                          onClick={deleteButtonHandler.bind({}, row[0])}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {props.td.length !== 0 ? props.children[1] : <></>}
      </div>
    </>
  );
};
export default Table;




export const userTableHeaders = [
    'الصورة الشخصية',
    'الاسم الأول',
    'الاسم الأخير',
    'رقم الهاتف',
];

export const statsTableHeaders = [
    'صورة المنتج',
    'اسم المنتج',
    'عدد مرات الطلب',
    'العائدات'
];

export const orderTableHeaders = [
    'العنوان',
    'الطابق',
    'رقم الهاتف',
    'الحالة',
];

export const companyTableHeaders = [
    'اسم الشركة',
    'اسم الشركة - الانكليزية',
];

export const productsTableHeaders = [
    'رقم المنتج',
    'اسم المنتج',
    'اسم الشركة - الانكليزية',
]

export const colorsTableHeaders = [
    'اسم اللون',
    'اسم اللون - الانكليزية',
]

export const sizesTableHeaders = [
    'القياس بالعربي',
    'القياس بالانكليزية',
]

export const CityTableHeaders=[
    "اسم المدينة"
]
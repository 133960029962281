import React, { useContext, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { DeleteColor, getAllColors, useActionColor } from "../../components/api/color";
import AddItem from "../../components/UI/AddItem";
import Spinner from "../../components/UI/Spinner";
import Table from "../../components/UI/Table";
import { colorsTableHeaders } from "../../constant/tableHeaders";
import ActionContext from "../../context/action-context";

const propNames = ["id", "nameAr", "nameEn"];

const Color = () => {
  const {useDeleteColor,useGetColors} = useActionColor();
  
  const {data,isLoading} = useGetColors;
  const {mutate} = useDeleteColor
  const deleteCTX = useContext(ActionContext);

  useEffect(() => {
    if (deleteCTX.id) {
      deleteCTX.setActionFunction(() => mutate);
    }
  }, [deleteCTX.id]);


  return (
    <>
      <AddItem
        url="add-color"
        title="كافة الألوان"
        text="في هذا الصفحة تستيطع ايجاد كافة الألوان المتاحة حاليا"
        buttonName="إضافة لون"
      />
     {!isLoading &&  <Table
        title="جدول الألوان"
        td={data}
        th={colorsTableHeaders}
        propsTable={propNames}
        currentRouter="color"
      >
        <div></div>
        <div></div>
      </Table>} {
        isLoading && <Spinner />
      }
    </>
  );
};

export const loader = async () => {
  return await getAllColors();
};
export default Color;

const API_CONTROLERS = {
    ACCOUTN: "Account",
    CP_ACCOUTN: "CPAccount",
    ADVERTISMENT: "Advertisment",
    CP_ORDER: "cp/CPOrder",
    ORDER: 'Order',
    PRODUCT: 'Product',
    CP_PRODUCT: 'cp/CPProduct',
    COLOR: 'Color',
    SETTING: 'Setting',
    CITY: 'City',
    CATEGORY: 'Category',
    BRAND: 'Brand',
    SIZE: 'Size',
    CP_BASIC: 'cp/CPBasic'
}


export default API_CONTROLERS;
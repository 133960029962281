import React from "react";
import './Spinner.css';
import showAlert from "./Alert";

const Spinner = () => {
  return (<>
  <div className="spinner inline-auto mt-1"></div>
  </>);
};

export default Spinner;

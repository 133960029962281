export function objectToArray(obj) {
  let myArray = [];

  for (let [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      myArray.push(value[0]);
    } else myArray.push(value);
  }

  return myArray;
}

export function getArrayWithSpecialProp(arr, propsName) {
  let tempArr = [],
    myArray = [];

  arr.forEach((obj) => {
    propsName.forEach((p) => {
      tempArr.push(obj[p]);
    });
    myArray.push(tempArr);
    tempArr = [];
  });

  return myArray;
}

export const delay = (ms) => {
  let id;
  const promise = new Promise((res) => {
    id = setTimeout(res, ms);
  });
  return {
    id,
    promise,
  };
};

export const b64toBlob = (b64Data, contentType = "") => {
  const byteCharacters = window.atob(b64Data);

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArrays = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArrays], { type: contentType });

  return blob;
};

export const convertUrlToFile = (url, file) => {
  const imgName = url.split("\\")[url.split("\\").length - 1];
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      file = new File([blob], imgName, {
        type: "image/" + imgName.split(".")[1],
      });
    });
};

export async function srcToFile(src, fileName, mimeType, signal) {
  return fetch(src, {
    signal: signal,
    mode: "no-cors",
  })
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], fileName, { type: mimeType });
    });
}

export const initialFiles = async (imgs, setFiles) => {
  const files = [];
  await imgs.map(async (url) => {
    const imgName = url.split("\\")[url.split("\\").length - 1];
    srcToFile(url, imgName, imgName.split(".")[1]).then((file) =>
      files.push(file)
    );
  });

  return files;
};
// export const toDataURL = (url) =>
//   fetch(url)
//     .then((response) => response.blob())
//     .then(
//       (blob) =>
//         new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onloadend = () => resolve(reader.result);
//           reader.onerror = reject;
//           reader.readAsDataURL(blob);
//         })
//     );

// export function dataURLtoFile(dataurl, filename) {
//   var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
//       bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
//       while(n--){
//           u8arr[n] = bstr.charCodeAt(n);
//       }
//       return new File([u8arr], filename, {type:mime});
//   }

export const createFormData = (values) => {
  const formData = new FormData();
  for (let key in values) {
    if (values[key] !== null)
      if (Array.isArray(values[key]))
        values[key].map((v) => formData.append(key, v));
      else formData.append(key, values[key]);
  }
  return formData;
};

export const handleRequestValues = (values) => {
  const newValues = {};
  for (let key in values) {
    if (values[key] !== null)
      if (Array.isArray(values[key]))
        values[key].map((v) => (newValues[key] = v));
      else newValues[key] = values[key];
  }
  return newValues;
};

export const editInitialValues = (values) => {};

export function base64ImageToBlob(str) {
  // extract content type and base64 payload from original string
  var pos = str.indexOf(";base64,");
  var type = str.substring(5, pos);
  var b64 = str.substr(pos + 8);

  // decode base64
  var imageContent = atob(b64);

  // create an ArrayBuffer and a view (as unsigned 8-bit)
  var buffer = new ArrayBuffer(imageContent.length);
  var view = new Uint8Array(buffer);

  // fill the view, using the decoded base64
  for (var n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  // convert ArrayBuffer to Blob
  var blob = new Blob([buffer], { type: type });

  return blob;
}

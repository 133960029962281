import React, { Suspense, useEffect, useState } from "react";
import { Await, defer, useLoaderData, useParams } from "react-router-dom";
import { createFormData } from "../../helpers/helper";
import {
  GetAdvertisment,
  GetAllAdvertisments,
  useSetAdvertisment,
} from "../../components/api/advertisment";
import { INITIAL_ADVERTISMENT_VALUES } from "../../constant/InitialValues";
import { getProducts } from "../../components/api/products";
import MultipleSelect from "../../components/Form/MultipleSelect";
import SelectInput from "../../components/Form/SelectInput";
import Spinner from "../../components/UI/Spinner";
import Form from "../../components/Form/Form";
import { Formik } from "formik";
import InputFile from "../../components/Form/InputFile";
import useForm from "../../hooks/useForm";
import { editAdvertismentValues } from "../../helpers/assign";
import Skeleton from "../../components/UI/Skeleton";
let initialValues = Object.create(INITIAL_ADVERTISMENT_VALUES);

const categoryTypeNames = [
  { id: 0, name: "Offer" },
  { id: 1, name: "Slides" },
  { id: 2, name: "Rectangle" },
  { id: 3, name: "Grid" },
];

const AddAdvertisment = () => {
  const loadedData = useLoaderData();
  const [files, setFiles] = useState([]);
  const { submitHandler, getSingleItemHandler, isLoading, isSubmitting } =
    useForm();
  const { id } = useParams();
  const mutation = useSetAdvertisment();

  useEffect(() => {
    if (id) {
      getSingleItemHandler(GetAdvertisment, id).then(({ data: { result } }) => {
        editAdvertismentValues(result, initialValues);
      });
    }
    return () => {
      initialValues = Object.create(INITIAL_ADVERTISMENT_VALUES);
    };
  }, []);

  const submitAdvertismentHandler = async (values) => {
    console.log({values})
    values.ImageUrl = files;
    const formData = createFormData(values);
    submitHandler(mutation.mutateAsync, formData);
  };

  if (isLoading) return <Spinner />;

  return (
    <Formik initialValues={initialValues} onSubmit={submitAdvertismentHandler}>
      {({ handleSubmit, setFieldValue, values }) => {
        const ration =
          values.Type === 3 ? undefined : values.Type === 0 ? 9 / 11 : 400 / 85;
        return (
          <Form
            urlLink="/alpha/advertisments"
            encType="multipart/form-data"
            method="post"
            className="flex flex-column"
            title={(id ? "تعديل " : "إضافة ") + "إعلان"}
            onSubmit={handleSubmit}
            isLoading={isSubmitting}
          >
            <Suspense fallback={<Skeleton label="نوع الإعلان" />}>
              <Await resolve={loadedData.advertisment}>
                {() => {
                  // const defaultAdvertismentData = advertismentsData.filter((adv)=> adv.id === advertisment.type)
                  return (
                    <SelectInput
                      id="Type"
                      options={categoryTypeNames}
                      label="نوع الإعلان"
                      setFieldValue={setFieldValue}
                      value={values.Type}
                    ></SelectInput>
                  );
                }}
              </Await>
            </Suspense>

            <Suspense fallback={<Skeleton label="منتج الإعلان:" />}>
              <Await resolve={loadedData.products}>
                {({ data }) => {
                  const productsData = data?.map((product) => ({
                    id: product?.id,
                    name: product?.nameEn,
                  }));
                  return (
                    <MultipleSelect
                      id="ProductIds"
                      options={productsData}
                      label="منتج الإعلان:"
                      setFieldValue={setFieldValue}
                      value={values.ProductIds}
                    ></MultipleSelect>
                  );
                }}
              </Await>
            </Suspense>
            <InputFile
              id="ImageUrl"
              text="غلاف الإعلان"
              type="file"
              value={values.ImageUrl}
              setFieldValue={setFieldValue}
              setFiles={setFiles}
              files={files}
              ratio={ration}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export const loader = async () => {
  const response = defer({
    advertisment: await GetAllAdvertisments(),
    products: getProducts(),
  });

  return response;
};

export default AddAdvertisment;

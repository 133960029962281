import axios from "axios";

// export const BASE_URL = process.env.REACT_APP_API_URL;
export const BASE_URL = "http://api.alpha-brand.net/";
// export const BASE_URL = "http://3.223.131.190:83/";




const apiInstance = axios.create({
  baseURL: BASE_URL + "api/",
  headers: {
    accept: "text/plain",
  },
});

apiInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) config.headers.setAuthorization(`Bearer ${token}`);
  return config;
});

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      window.location.reload();
      localStorage.clear();
    } else throw error.response?.data.errorMessage;
  }
);

export default apiInstance;

import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import NotificationContext from "../context/notification-context";

const useForm = () => {
  const notificationCtx = useContext(NotificationContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitHandler = async (onSubmit, values) => {
    try {
      setIsSubmitting(true);
      await onSubmit(values);
      setIsSubmitting(false);
      navigate(-1);
    } catch (error) {
      notificationCtx.setMessage(error);
      setIsSubmitting(false);
    }
  };

  const getSingleItemHandler = async (requestFun, id) => {
    try {
      setIsLoading(true);
      const response = await requestFun(id);
      setIsLoading(false);
      return response;
    } catch (error) {
      notificationCtx.setMessage(error);
    }
  };

  return { submitHandler, getSingleItemHandler, isSubmitting, isLoading };
};

export default useForm;

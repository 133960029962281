import React from 'react'
import classes from './AlphaLoading.module.css';


const AlphaLoading = () => {
  
  return (
    <div className= {classes.loading + " place-center text-center"}>
        <h1>A</h1>
        <span>الرجاء الانتظار...</span>
    </div>
  )
}

export default AlphaLoading
import React, { useContext, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import {
  GetAllAdvertisments,
  RemoveAdvertisment,
  useDeleteAdvertisment,
  useGetAdvertisments,
} from "../../components/api/advertisment";
import AddItem from "../../components/UI/AddItem";
import Grid from "../../components/UI/Grid";
import Spinner from "../../components/UI/Spinner";
import ActionContext from "../../context/action-context";

const Advertisment = () => {
  const { data, isLoading } = useGetAdvertisments();
  const {mutate} = useDeleteAdvertisment()
  const deleteCTX = useContext(ActionContext);

  useEffect(() => {
    deleteCTX.setActionFunction(() => mutate);
  }, []);

  return (
    <>
      <AddItem
        url="add-advertisment"
        title="كافة الإعلانات"
        buttonName="إضافة إعلان"
        text="في هذا الصفحة تستيطع ايجاد كافة الإعلانات المتاحة حاليا"
      />
      {!isLoading && (
        <Grid gridData={data} currentRouter="advertisment" />
      )}
    </>
  );
};

export const loader = async () => {
  return GetAllAdvertisments();
};

export default Advertisment;

import apiInstance from "./apiInstance";
import API_CONTROLERS from "../../constant/apiControles";
import API_ROUTES from "../../constant/apiEnd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const GetCities = async () => {
    const response = await apiInstance.get(
      API_CONTROLERS.CP_BASIC + API_ROUTES.CITY.GET_CITIES
    );

    return response;
};

export const GetCityById = async (id) => {
    const response = await apiInstance.get(
      API_CONTROLERS.CITY + API_ROUTES.CITY.GET_CITY,{params: {
        id
      }}
    );

    return response;
};

export const setCity = async (city) => {
    const { data } = await apiInstance.post(
      API_CONTROLERS.CITY + API_ROUTES.CITY.SET_CITY,{
        ...city
      }
    );
    return data;
};
export const DeleteCity = async (id) => {
  const response = apiInstance.delete(
    API_CONTROLERS.CITY + API_ROUTES.CITY.REMOVE_CITY,
    {
      params: {
        id,
      },
    }
  );
  return response;
};

export const useCityActions= (page) => {
  const queryClient = useQueryClient();
  const previousCities = queryClient.getQueryData(["Cities"]);
  const { id } = useParams();

  const useGetCities = useQuery({
    queryKey: ["Cities"],
    queryFn: GetCities,
  });
  const useSetCity = useMutation({
    mutationFn: (newCity) => setCity(newCity),
    onSuccess: (newCity) => {
      const successMessage =
        "تم" + (id ? " تعديل" : " إضافة") + " الشركة بنجاح";
      queryClient.getDefaultOptions().mutations.onSuccess(successMessage);

      queryClient.invalidateQueries(["Cities", page]);
  
    },
    onError: (error, v, rollback) => {
      // rollback();
      queryClient.getDefaultOptions().mutations.onError(error);
    },
  }); 



  const useDeleteCity = useMutation({
    mutationFn: (id) => DeleteCity(id),
    onMutate: async (id) => {
      await queryClient.cancelQueries(["Cities",page]);
      // const previousCities = queryClient.getQueryData(["Cities",page]);
      // queryClient.setQueryData(["Cities",page], (oldCity) =>
      //   oldCity.data.result.filter((oldCity) => oldCity.id !== id)
      // );
      // return previousCities ;
    },
    onSuccess: () => {
      queryClient.getDefaultOptions().mutations.onSuccess("تم حذف المدينة بنجاح");
    },
    onError: (error, _, context) => {
      if (context?.previousCities) {
        queryClient.setQueryData(["Cities",page], context.previousCities);
      }
      queryClient.getDefaultOptions().mutations.onError(error);
    },
  });

  return {
    useGetCities,
    useSetCity,
    useDeleteCity,
  };
};




import apiInstance, { BASE_URL } from "./apiInstance";
import API_ROUTES from "../../constant/apiEnd";
import API_CONTROLES from "../../constant/apiControles";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const loginUser = async (password, username) => {
  const response = await apiInstance.post(
    API_CONTROLES.CP_ACCOUTN + API_ROUTES.ACCOUNT.LOGIN_USER,
    {},
    {
      params: {
        password,
        username,
      },
    }
  );

  return response;
};

export const getAllAccounts = async ({ queryKey }) => {
  const [_, pageNumber, search] = queryKey;
  const { data } = await apiInstance.get(
    API_CONTROLES.ACCOUTN + API_ROUTES.ACCOUNT.GET_ACCOUNTS,
    {
      params: {
        pageNumber: pageNumber,
        query: search,
      },

    },
  );
  return data.result;
};

export const setUser = async (userData) => {
  const response = await apiInstance.post(
    API_CONTROLES.CP_ACCOUTN + API_ROUTES.ACCOUNT.SET_USER,
    userData
  );
  return response;
};

export const GetAccount = async (id) => {
  const response = await apiInstance.get(
    API_CONTROLES.CP_ACCOUTN + API_ROUTES.ACCOUNT.GET_ACCOUNT,
    { params: { id } }
  );

  return response;
};

export const GetAccountByPhone = async (phoneNumber) => {
  const response = await apiInstance.get(
    API_CONTROLES.ACCOUTN + API_ROUTES.ACCOUNT.GET_ACCOUNT_BY_PHONE,
    { params: { phoneNumber } }
  );

  return response;
};

export const blockAccount = async (id) => {
  const response = apiInstance.put(
    API_CONTROLES.ACCOUTN + API_ROUTES.ACCOUNT.BLOCK_ACCOUNT,
    {},
    {
      params: {
        id,
      },
    }
  );

  return response;
};

export const changePassword = async ({ oldPassword, newPassword }) => {
  const response = await apiInstance.post(
    API_CONTROLES.CP_ACCOUTN + API_ROUTES.ACCOUNT.CHANGE_PASSWORD,
    {},
    {
      params: {
        oldPassword,
        newPassword,
      },
    }
  );

  return response;
};

export const useUserActions = (page,searchQuery) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const previousUsers = queryClient.getQueryData(["users", page,searchQuery]);

  const isLastUser =
    previousUsers?.totalPage === 1 && previousUsers?.data.length === 1;

  const useGetUsers = useQuery({
    queryKey: ["users", page,searchQuery],
    queryFn: getAllAccounts,
    keepPreviousData: true,
  });

  const useSetUser = useMutation({
    mutationFn: (newUser) => setUser(newUser),
    onSuccess: () => {
      const successMessage =
        "تم" + (id ? " تعديل" : " إضافة") + " المستخدم بنجاح";
      queryClient.invalidateQueries(["users", page]);
      queryClient.getDefaultOptions().mutations.onSuccess(successMessage);
    },
  });

 

  const useDeleteUser = useMutation({
    mutationFn: blockAccount,
    onMutate: async (id) => {
      await queryClient.cancelQueries(["users", page, searchQuery]);
      const previousData = queryClient.getQueryData(["users", page, searchQuery]);

      queryClient.setQueryData(["users", page, searchQuery], (oldData) => {
        return {
          ...oldData,
          data: oldData.data.filter((user) => user.id !== id),
        };
      });

      return { previousData };
    },
    onError: (err, id, context) => {
      queryClient.setQueryData(["users", page, searchQuery], context.previousData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["users", page, searchQuery]);
      queryClient
            .getDefaultOptions()
            .mutations.onSuccess("تم حذف المستخدم بنجاح");
        
    },
  });

  return {
    useGetUsers,
    useSetUser,
    useDeleteUser,
  };
};






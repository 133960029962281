
import apiInstance from "./apiInstance";
import API_CONTROLERS from "../../constant/apiControles";
import API_ROUTES from "../../constant/apiEnd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const setProduct = async (data) => {
  const response = apiInstance.post(
    API_CONTROLERS.PRODUCT + API_ROUTES.PRODUCT.SET_PRODUCT,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response;
};

export const getProducts = async (params) => {
  const search = params?.queryKey[2];
  const response = await apiInstance.get(
    API_CONTROLERS.CP_BASIC + API_ROUTES.PRODUCT.GET_LATEST_PRODUCTS,
    {
      params: {
        pageNumber: params?.queryKey[1] ,
        query: search,
      },
    }
  );
  return response.data.result ;
};

export const getCategoryProducts = async () => {
  const response = apiInstance.post(
    API_CONTROLERS.PRODUCT + API_ROUTES.PRODUCT.GET_CATEGORY_PRODUCTS
  );

  return response;
};

export const getTrendingProducts = async () => {
  const response = apiInstance.post(
    API_CONTROLERS.PRODUCT + API_ROUTES.PRODUCT.GET_TRENDING_PRODUCTS
  );

  return response;
};

export const getProduct = async (id) => {
  const response = apiInstance.get(
    API_CONTROLERS.CP_PRODUCT + API_ROUTES.PRODUCT.GET_PRODCUT,
    { params: { id } }
  );

  return response;
};

export const deleteProdcut = async (id) => {
  const response = apiInstance.delete(
    API_CONTROLERS.PRODUCT + API_ROUTES.PRODUCT.DELETE_PRODUCT,
    { params: { id } }
  );

  return response;
};

export const deleteProdcutFile = async (FileId) => {
  const response = apiInstance.delete(
    API_CONTROLERS.PRODUCT + API_ROUTES.PRODUCT.DELETE_PRODUCT_FILE,
    { params: { FileId } }
  );

  return response;
};

export const useProductsActions = (page , searchQuery) => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const previousData = queryClient.getQueryData(["products", page, searchQuery]);

  const isLastProduct =
    previousData?.totalPage === 1 && previousData?.data.length === 1;

  const useGetProducts = useQuery({
    queryKey: ["products", page , searchQuery],
    queryFn: getProducts,
    keepPreviousData: true,
  });

  const useSetProduct = useMutation({
    mutationFn: (newProduct) => setProduct(newProduct),
    onSuccess: () => {
      const successMessage =
        "تم" + (id ? " تعديل" : " إضافة") + " المنتج بنجاح";
      queryClient.invalidateQueries(["products", page]);
      queryClient.getDefaultOptions().mutations.onSuccess(successMessage);
    },
  });

 

  const useDeleteProduct = useMutation({
    mutationFn: (id) => deleteProdcut(id),
    onMutate: (id) => {
      queryClient.setQueryData(["products", page, searchQuery], (oldProducts) => ({
        ...oldProducts,
        totalPage: isLastProduct ? 0 : oldProducts.totalPage,
        data: oldProducts.data.filter((product) => product.id !== id),
      }));

      return () => queryClient.setQueryData(["products", page, searchQuery], previousData);
    },
    onSuccess: () => {
      queryClient
        .getDefaultOptions()
        .mutations.onSuccess("تم حذف المنتج بنجاح");
    },
    onError: (error, _, rollback) => {
      queryClient.getDefaultOptions().mutations.onError(error);
      rollback && rollback();
    },
  });

  return {
    useGetProducts,
    useSetProduct,
    useDeleteProduct,
  };
};



import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import Backdrop from "../UI/Backdrop";
import "./SelectFilter.css";

const SelectInput = (props) => {
  const inputRef = useRef();
  const [showSelectList, setSelectList] = useState(false);

  const options =
    props.options?.length !== 0
      ? props.options
      : [{ id: -1, name: "الرجاء قم بإدخال بيانات" }];
  console.log(options)
  const list = {
    hidden: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1, y: -10 },
  };
  const item = { hidden: { y: 30 }, visible: { y: 0 } };

  const isValueCurrect = options?.find((opt) => opt.id === props.value);

  const currentValue =
    isValueCurrect !== undefined ? isValueCurrect : options[0];

  useEffect(() => {
    props.setFieldValue(props.id, currentValue?.id);
  }, []);

  const onChangeHandler = (id) => {
    props.setFieldValue(props.id, id);
  };

  const toggleShowList = () => {
    setSelectList((prevState) => !prevState);
  };

  const hideSelectList = useCallback(() => {
    setSelectList(false);
  }, []);

  return (
    <div className="filter-content grid gap-10">
      <label htmlFor={props.id}>{props.label}</label>
      <div
        className={`select ${showSelectList && "on-top"}`}
        onClick={toggleShowList}
        tabIndex={0}
      >
        <div className="currentValue">
          {currentValue.name}
          <input
            id={props.id}
            name={props.id}
            className="input"
            ref={inputRef}
            value={props.value}
            onChange={props.onChange}
            hidden
          />
        </div>
        <AnimatePresence>
          {showSelectList && (
            <motion.div
              className="options"
              variants={list}
              initial={"hidden"}
              animate={"visible"}
              transition={{ duration: 0.3, staggerChildren: 0.1 }}
              exit={{ opacity: 0, scale: 0, y: 100 }}
            >
              {options?.map((opt) => {
                return (
                  <motion.div
                    id={`${opt.id}`}
                    key={opt.id + opt.name}
                    onClick={onChangeHandler.bind({}, opt.id)}
                    className={opt.id === props.value ? "active" : ""}
                    variants={item}
                    layoutScroll
                  >
                    {opt.name}
                  </motion.div>
                );
              })}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {showSelectList &&
        createPortal(
          <Backdrop onClick={hideSelectList} />,
          document.getElementById("backdrop")
        )}
    </div>
  );
};

export default SelectInput;

import React, { lazy } from "react";
import {
  createHashRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import { loader as addAdvertismentLoader } from "./pages/advertisment/AddAdvertisment";
import { loader as advertismentsLoader } from "./pages/advertisment/Advertisment";
import { loader as colorLoader } from "./pages/colors/Color";
import { loader as addProductLoader } from "./pages/products/AddProduct";
import {
  action as actionSetting,
  loader as settingsLoader,
} from "./pages/settings/Settings";
import { loader as sizesLoader } from "./pages/size/Sizes";
import AddUser, { loader as addUserloader } from "./pages/users/AddUser";

const MainPage = lazy(() => import("./pages/Alpha"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const LoginPage = lazy(() => import("./pages/login"));
const Products = lazy(() => import("./pages/products/Products"));
const AddProduct = lazy(() => import("./pages/products/AddProduct"));
const Users = lazy(() => import("./pages/users/Users"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));

const ProtectedRoute = lazy(() => import("./pages/ProtectedRoute"));

const ShowOrder = lazy(() => import("./pages/orders/ShowOrder"));
const PendingOrders = lazy(() => import("./pages/orders/PendingOrders"));
const RejectedOrders = lazy(() => import("./pages/orders/RejectedOrders"));
const ConfirmOrders = lazy(() => import("./pages/orders/ConfirmOrders"));

const Advertisment = lazy(() => import("./pages/advertisment/Advertisment"));
const AddAdvertisment = lazy(() =>
  import("./pages/advertisment/AddAdvertisment")
);

const Colors = lazy(() => import("./pages/colors/Color"));
const AddColor = lazy(() => import("./pages/colors/AddColor"));

const Sizes = lazy(() => import("./pages/size/Sizes"));
const AddSize = lazy(() => import("./pages/size/AddSize"));

const Companies = lazy(() => import("./pages/companies/Companies"));
const AddCompany = lazy(() => import("./pages/companies/AddCompany"));

const Category = lazy(() => import("./pages/category/Category"));
const AddCategory = lazy(() => import("./pages/category/AddCategory"));

const Settings = lazy(() => import("./pages/settings/Settings"));
const Password = lazy(() => import("./pages/settings/Password"));


const Cities = lazy(() => import("./pages/Cities/Cities"));
const AddCities = lazy(() => import("./pages/Cities/AddCities"));


const routes = createHashRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorPage />}>
      <Route path="login" element={<LoginPage />} />
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/alpha" element={<Dashboard />}>
          <Route index element={<MainPage />} />
          <Route path="users">
            <Route index element={<Users />} />
            <Route
              path="add-user"
              element={<AddUser />}
              loader={addUserloader}
            />
            <Route path=":id" element={<AddUser />} loader={addUserloader} />
          </Route>
          <Route path="products">
            <Route index element={<Products />} />
            <Route
              path="add-product"
              element={<AddProduct />}
              loader={addProductLoader}
            />
            <Route
              path=":id"
              element={<AddProduct />}
              loader={addProductLoader}
            />
          </Route>

          <Route path="categories">
            <Route index element={<Category />} />
            <Route path="add-category" element={<AddCategory />} />
            <Route path=":id" element={<AddCategory />} />
          </Route>

          <Route>
            <Route path="rejected-orders" element={<RejectedOrders />} />
            <Route path="confirm-orders" element={<ConfirmOrders />} />
            <Route path="pending-orders" element={<PendingOrders />} />
            <Route path="show-order/:id" element={<ShowOrder />} />
          </Route>

          <Route path="advertisments">
            <Route
              index
              element={<Advertisment />}
              loader={advertismentsLoader}
            />
            <Route
              path="add-advertisment"
              element={<AddAdvertisment />}
              loader={addAdvertismentLoader}
            />
            <Route
              path=":id"
              element={<AddAdvertisment />}
              loader={addAdvertismentLoader}
            />
          </Route>
          <Route path="companies">
            <Route index element={<Companies />} />
            <Route path="add-company" element={<AddCompany />} />
            <Route path=":id" element={<AddCompany />} />
          </Route>
          <Route path="colors">
            <Route index element={<Colors />} loader={colorLoader} />
            <Route path="add-color" element={<AddColor />} />
            <Route path=":id" element={<AddColor />} />
          </Route>
          <Route path="sizes">
            <Route index element={<Sizes />} loader={sizesLoader} />
            <Route path="add-size" element={<AddSize />} />
            <Route path=":id" element={<AddSize />} />
          </Route>


          
          <Route path="Cities">
            <Route index element={<Cities />} />
            <Route
              path="add-City"
              element={<AddCities />}
              loader={addUserloader}
            />
            <Route path=":id" element={<AddCities />} loader={addUserloader} />
          </Route>
          <Route path="settings">
            <Route
              index
              element={<Settings />}
              loader={settingsLoader}
              action={actionSetting}
            />
            <Route
              path="change-password"
              element={<Password />}
              // loader={settingsLoader}
              // action={actionSetting}
            />
          </Route>
        </Route>
      </Route>
      <Route index element={<Navigate to={"login"} />} />
    </Route>
  )
);

export default routes;

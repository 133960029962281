import {
  useIsFetching,
  useQueryClient,
} from "@tanstack/react-query";
import React, { Suspense, useContext } from "react";
import { createPortal } from "react-dom";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import { Alert } from "./components/UI/Alert";
import AlphaLoading from "./components/UI/AlphaLoading";
import Spinner from "./components/UI/Spinner";
import NotificationContext from "./context/notification-context";
import routes from "./Routes";

React.debugCurrentFrameStack = true;

function App() {
  const queryClient = useQueryClient();
  const notificationCtx = useContext(NotificationContext);
  const isFetching = useIsFetching();


  queryClient.setDefaultOptions({
    mutations: {
      onSuccess: (message) => {
        notificationCtx.setMessage(message);
        notificationCtx.setMessageType("success");
      },
      onError: (error) => {
        notificationCtx.setMessage(error);
      },
    },
    queries: {
      onError: (error) => {
        notificationCtx.setMessage(error);
      },
    },
  });


  return (
    <>
      {createPortal(
        <Alert
          isOpen={notificationCtx.message}
          type={notificationCtx.messageType}
        ></Alert>,
        document.getElementById("alert")
      )}
      {isFetching === 1 && (
        <div className="loader">
          <Spinner />
        </div>
      )}
      <Suspense fallback={<AlphaLoading />}>
        <RouterProvider router={routes} fallbackElement={<AlphaLoading />} />
      </Suspense>
    </>
  );
}

export default App;

import React, { useContext, useCallback } from "react";
import Button from "./Button";
import classes from "./Card.module.css";
import useDeleteModel from "../../hooks/use-delete";
import DeleteModel from "./GenericModel";
import ActionContext from "../../context/action-context";
import { createPortal } from "react-dom";
import { motion } from "framer-motion";
import DeleteIcon from "../../assets/deleteIcon";
import EditIcon from "../../assets/editIcon";
import { BASE_URL } from "../api/apiInstance";

const Card = (props) => {
  const ActionCtx = useContext(ActionContext);
  const { openDeleteModle, hideDeleteModelHandler, showDeleteModelHandler } =
    useDeleteModel();

  const deleteButtonHandler = useCallback((id) => {
    console.log(id);
    showDeleteModelHandler();
    ActionCtx.setActionId(id);
  }, []);

  return (
    <>
      {openDeleteModle &&
        createPortal(
          <DeleteModel cancelFun={hideDeleteModelHandler} />,
          document.getElementById("model")
        )}
      <motion.div
        className={classes.card}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <div className={classes["card-image"]}>
          <img
            src={props.cardData.imageUrl}
            alt={props.cardData.nameAr || props.cardData.name}
          />
        </div>
        <div className="content flex flex-column align-center gap-20">
          <h3>{props.cardData.nameAr}</h3>
          <motion.div
            className={classes["card-action"] + " flex gap-10 "}
            whileHover={{ opacity: 1 }}
          >
            <EditIcon
              width="4rem"
              fColor="var(--edit-c)"
              onClick={props.onEditCard.bind({}, props.cardData.id)}
              style={{backgroundColor: 'var(--bg-white)',borderRadius: '50%',padding: '.5rem'}}
            />
            <DeleteIcon
              width="4rem"
              fColor="var(--delete-c)"
              onClick={deleteButtonHandler.bind({}, props.cardData.id)}
              style={{backgroundColor: 'var(--bg-white)',borderRadius: '50%',padding: '.5rem'}}
            />
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};

export default Card;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { AuthContextProvider } from "./context/auth-context";
import { ActionContextProvider } from "./context/action-context";
import { NotificationContextProvider } from "./context/notification-context";
import { PaginationContextProvider } from "./context/pagination-context";


const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <NotificationContextProvider>
      <QueryClientProvider client={queryClient}>
        <ActionContextProvider>
          <AuthContextProvider>
            <PaginationContextProvider>
              <App />
           
            </PaginationContextProvider>
          </AuthContextProvider>
        </ActionContextProvider>
      </QueryClientProvider>
    </NotificationContextProvider>
  </React.StrictMode>
);


import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import Backdrop from "../UI/Backdrop";
import { IoClose } from "react-icons/io5"; 
import classes from "./MultipleSelect.module.css";
import "./SelectFilter.css";

const MultipleSelect = (props) => {
  const [showSelectList, setShowSelectList] = useState(false);
  const inputRef = useRef();
  const options =
    props.options?.length !== 0
      ? props.options
      : [{ id: -1, name: "الرجاء قم بإدخال بيانات" }];

  const list = {
    hidden: { opacity: 0, scale: 0 },
    visible: { opacity: 1, scale: 1, y: -10 },
  };
  const item = { hidden: { y: 30 }, visible: { y: 0 } };

  useEffect(() => {
    if (props.value.length === 0) {
      props.setFieldValue(props.id, [options[0].id]);
    }
  }, []);

  const removeSelectedOption = (id) => {
    if (props.value.length > 1) {
      const newSelectedOptions = props.value.filter((value) => value !== id);
      props.setFieldValue(props.id, newSelectedOptions);
    }
  };
  const changeSelectListHandler = (e) => {
    const newSelectId = +e.currentTarget.id;
    const isSelected = props.value.some((value) => value === newSelectId);

    if (!isSelected) {
      const newSelectedOption = options.find((opt) => opt.id === newSelectId);
      props.setFieldValue(props.id, [...props.value, newSelectedOption.id]);
    }
  };

  const toggleShowList = useCallback(() => {
    setShowSelectList((prevState) => !prevState);
  }, []);

  const hideShowList = useCallback(() => {
    setShowSelectList(false);
  }, []);

  return (
    <>
      {showSelectList &&
        createPortal(
          <Backdrop onClick={hideShowList}></Backdrop>,
          document.getElementById("model")
        )}
      <div className="filter-content grid">
        <label htmlFor={props.id}>{props.label}</label>
        <input
          className={classes.select}
          name={props.id}
          id={props.id}
          multiple
          ref={inputRef}
          value={props.value}
          onChange={props.onChange}
        />
        <div className="select" onClick={toggleShowList} tabIndex={0}>
          <div className="currentValue flex gap-10 align-center">
            {props.value.map((value) => {
              const option = options.find((opt) => opt.id === value);
              return (
                <span key={value} className="selected-option">
                  {option.name}
                  <IoClose
                    className="remove-icon"
                    onClick={(e) => {
                      e.stopPropagation(); 
                      removeSelectedOption(value);
                    }}
                  />
                </span>
              );
            })}
          </div>
          <AnimatePresence>
            {showSelectList && (
              <motion.ul
                className="options"
                variants={list}
                initial={"hidden"}
                animate={"visible"}
                transition={{ duration: 0.3, staggerChildren: 0.1 }}
                exit={{ opacity: 0, scale: 0, y: 100 }}
              >
                {options.map((opt) => (
                  <motion.li
                    key={opt.id}
                    id={"" + opt.id}
                    className={
                      props.value.some((value) => value === opt.id)
                        ? "active"
                        : ""
                    }
                    variants={item}
                    onClick={changeSelectListHandler}
                  >
                    {opt.name}
                  </motion.li>
                ))}
              </motion.ul>
            )}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

export default MultipleSelect;

import React from "react";
import { useState } from "react";

const NotificationContext = React.createContext({
  message: null,
  messageType: null,
  setMessage: () => {},
  setMessageType: () => {},
});

export const NotificationContextProvider = (props) => {
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState("error");

  const initialValues = {
    message: message,
    messageType: messageType,
    setMessage: setMessage,
    setMessageType: setMessageType,
  };
  return (
    <NotificationContext.Provider value={initialValues}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;

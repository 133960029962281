import { Formik } from "formik";
import React, { useContext } from "react";
import { Suspense, useEffect, useState } from "react";
import { Await, defer, useLoaderData, useParams } from "react-router-dom";
import { number, object, string } from "yup";
import {
  GetAccount,
  setUser,
  useSetAccount,
  useUserActions,
} from "../../components/api/account";
import { GetCities } from "../../components/api/city";
import Form from "../../components/Form/Form";
import Input from "../../components/Form/Input";
import InputFile from "../../components/Form/InputFile";
import { default as SelectInput } from "../../components/Form/SelectInput";
import Skeleton from "../../components/UI/Skeleton";
import Spinner from "../../components/UI/Spinner";
import dialCodeArray from "../../constant/dialCode";
import { INITIAL_USER_VALUES } from "../../constant/InitialValues";
import PaginationContext from "../../context/pagination-context";
import { editUserValues } from "../../helpers/assign";
import { createFormData } from "../../helpers/helper";
import useForm from "../../hooks/useForm";


let initialValues = Object.create(INITIAL_USER_VALUES);

const userObjectSchema = object({
  PhoneNumber: number()
    .typeError("الرجاء إدخال رقم")
    .required("الرجاء إدخال الحقل")
    .positive("الرجاء إدخال رقم صالح"),
  FirstName: string().required("الرجاء إدخال الحقل"),
  LastName: string().required("الرجاء إدخال الحقل"),
  // .test(
  //   "len",
  //   "يجب إدخال رقم من 10 خانات",
  //   (val) => val.length > 8 && val.length < 11
  // ),
});

const accountRoleOptions = [
  { id: 1, name: "أدمن" },
  { id: 2, name: "مستخدم" },
];

const AddUser = () => {
  const paginationCtx = useContext(PaginationContext);
  const { useSetUser } = useUserActions(paginationCtx.userPage);
  const loadedData = useLoaderData();
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const { getSingleItemHandler, submitHandler, isLoading, isSubmitting } = useForm();
  const { mutateAsync } = useSetUser;

  useEffect(() => {
    if (id) {
      getSingleItemHandler(GetAccount, id).then(({ data: { result } }) => {
        editUserValues(result, initialValues);
      });
    }
    return () => {
      initialValues = Object.create(INITIAL_USER_VALUES);
    };
  }, []);

  const submitUserHandler = (values) => {
   
    values.ProfilePicture = files;
    if (files.length === 0) values.ProfilePicture = [];
    const formData = createFormData(values);
    console.log({ formData })
    submitHandler(mutateAsync, formData);
  };

  if (isLoading) return <Spinner />;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitUserHandler}
      validationSchema={userObjectSchema}
    >
      {({
        handleSubmit,
        getFieldProps,
        setFieldValue,
        values,
        errors,
        touched,
        handleChange,

        isValid,
      }) => (
        <Form
          urlLink="/alpha/users"
          title={(id ? "تعديل " : "إضافة ") + "مستخدم"}
          encType="multipart/form-data"
          method="post"
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
          disabled={!isValid}
        >
          <Input
            id="FirstName"
            text="الاسم الأول"
            type="text"
            error={errors.FirstName}
            touched={touched.FirstName}
            {...getFieldProps("FirstName")}
          />
          <Input
            id="LastName"
            text="الاسم الاخير"
            type="text"
            error={errors.LastName}
            touched={touched.LastName}
            {...getFieldProps("LastName")}
          />
          <Input
            id="Username"
            text="اسم المستخدم"
            type="text"
            {...getFieldProps("Username")}
          />
          <Input
            id="Address"
            text="العنوان"
            type="text"
            {...getFieldProps("Address")}
          />
          <SelectInput
            id="DialCode"
            options={dialCodeArray}
            label="رقم النداء "
            setFieldValue={setFieldValue}
            value={values.DialCode}
            onChange={handleChange}
          />
             <Input
            id="PhoneNumber"
            text="رقم الموبايل"
            type="tel"
            {...getFieldProps("PhoneNumber")}
            error={errors.PhoneNumber}
            touched={touched.PhoneNumber}
          />
          <SelectInput
            id="RoleId"
            options={accountRoleOptions}
            label="نوع المستخدم"
            setFieldValue={setFieldValue}
            value={values.RoleId}
            onChange={handleChange}
          />
          {values.RoleId == 1 &&
            <Input
              id="Password"
              text="كلمة السر"
              type="Password"
              {...getFieldProps("Password")}
              error={errors.Password}
              touched={touched.Password}
            />}
          {/* <Input
            id="PhoneNumber"
            text="رقم الموبايل"
            type="tel"
            {...getFieldProps("PhoneNumber")}
            error={errors.PhoneNumber}
            touched={touched.PhoneNumber}
          /> */}
          <Input
            id="Street"
            text="الشارع"
            type="text"
            {...getFieldProps("Street")}
          />
          <Suspense fallback={<Skeleton label="المدينة" />}>
            <Await resolve={loadedData.cities}>
              {({ data: { result } }) => {
                const citiesData = result.map((city) => ({
                  id: city.id,
                  name: city.nameAr,
                }));
                return (
                  <SelectInput
                    id="CityId"
                    options={citiesData}
                    label="المدينة"
                    value={values.CityId}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                  ></SelectInput>
                );
              }}
            </Await>
          </Suspense>

          
      
          
          <InputFile
            id="ProfilePicture"
            text="قم بإضافة صورة المستخدم"
            type="file"
            value={values.ProfilePicture}
            setFieldValue={setFieldValue}
            setFiles={setFiles}
            files={files}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddUser;

export const loader = async () => {
  return defer({ cities: GetCities() });
};

export const Action = async ({ request }) => {
  const formData = await request.formData();
  const response = await setUser(formData);
  return response;
};

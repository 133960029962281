import React, { useCallback, useContext, useEffect } from "react";
import classes from "./Grid.module.css";
import Card from "./Card";
import { useLocation, useNavigate } from "react-router-dom";

const Grid = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const editButtonHandler = useCallback((id) => {
    navigate(location.pathname +"/" + id, {});
  }, []);

  return (
    <div className={`${classes.grid} grid grid-5 gap-20 mt-1 radius-10`}>
      {props.gridData.map((card) => (
        <Card key={card.id} onEditCard={editButtonHandler} cardData={card} />
      ))}
    </div>
  );
};

export default Grid;

import React, { Suspense, useEffect, useState } from "react";
import Form from "../../components/Form/Form";
import { Await, defer, useLoaderData, useParams } from "react-router-dom";
import Input from "../../components/Form/Input";
import SelectInput from "../../components/Form/SelectInput";
import { GetCategories } from "../../components/api/category";
import { GetBrands } from "../../components/api/company";
import { getAllColors } from "../../components/api/color";
import { getSizes } from "../../components/api/size";
import MultipleSelect from "../../components/Form/MultipleSelect";
import {
  deleteProdcutFile,
  getProduct,
  useProductsActions,
} from "../../components/api/products";
import { createFormData } from "../../helpers/helper";
import InputFile from "../../components/Form/InputFile";
import { Formik } from "formik";
import { INITIAL_PRODUCTS_VALUES } from "../../constant/InitialValues";
import useForm from "../../hooks/useForm";
import { editProductValues } from "../../helpers/assign";
import { object, string } from "yup";
import Skeleton from "../../components/UI/Skeleton";
import Spinner from "../../components/UI/Spinner";
import { useContext } from "react";
import PaginationContext from "../../context/pagination-context";
import { BASE_URL } from "../../components/api/apiInstance";

let initialValues = Object.create(INITIAL_PRODUCTS_VALUES);

const productsSchema = object({
  NameEn: string().required("الرجاء إدخال اسم للمنتج"),
  DescriptionEn: string().required("الرجاء إدخال وصف للمنتج"),
});
const AddProduct = () => {
  const loadedData = useLoaderData();
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const paginationCtx = useContext(PaginationContext);
  const { submitHandler, isLoading, isSubmitting, getSingleItemHandler } =
    useForm();
  const { useSetProduct } = useProductsActions(paginationCtx.productPage);
  const mutation = useSetProduct;

  useEffect(() => {
    if (id) {
      getSingleItemHandler(getProduct, id).then(({ data: { result } }) => {
        editProductValues(result, initialValues);
        setFiles([...initialValues.Images]);
      });
    }
    return () => {
      initialValues = Object.create(INITIAL_PRODUCTS_VALUES);
    };
  }, []);

  const submitPorductHandler = async (values) => {
    const submitValues = Object.create(values);
    if (submitValues.Tags.lenght !== 0)
      submitValues.Tags = submitValues.Tags.trim().split(" ");
    submitValues.Images = files.filter((file) => typeof file === "object");
    const formData = createFormData(submitValues);
    submitHandler(mutation.mutateAsync, formData);
  };

  if (isLoading) return <Spinner />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitPorductHandler}
      validationSchema={productsSchema}
      validateOnBlur={false}
    >
      {({
        handleSubmit,
        values,
        setFieldValue,
        getFieldProps,
        handleChange,
        errors,
        touched,
        isValid,
      }) => (
        <Form
          urlLink="/alpha/products"
          encType="multipart/form-data"
          title={(id ? "تعديل " : "إضافة ") + "منتج"}
          onSubmit={handleSubmit}
          isLoading={isSubmitting}
          disabled={!isValid}
        >
          <Input
            id="NameAr"
            text="اسم المنتج"
            type="text"
            {...getFieldProps("NameAr")}
            isRequire
           
        
          />
          <Input
            id="NameEn"
            text="اسم المنتج بالانكليزية"
            type="text"
            error={errors.NameEn}
            touched={touched.NameEn}
            {...getFieldProps("NameEn")}
          />
          <Input
            id="DescriptionAr"
            text="وصف المنتج"
            type="text"
            {...getFieldProps("DescriptionAr")}
            isRequire
          />
          <Input
            id="DescriptionEn"
            text="وصف المنتج بالانكليزية"
            type="text"
            error={errors.DescriptionEn}
            touched={touched.DescriptionEn}
            {...getFieldProps("DescriptionEn")}
          />
          <SelectInput
            id="GenderType"
            options={[
              { id: 0, name: "ذكر" },
              { id: 1, name: "أنثى" },
              { id: 2, name: "أطفال" },
            ]}
            value={values.GenderType}
            setFieldValue={setFieldValue}
            label="الجنس"
            onChange={handleChange}
          ></SelectInput>
          <Suspense fallback={<Skeleton label="صنف المنتج" />}>
            <Await resolve={loadedData?.category}>
              {(result) => {
                const categoriesData = result?.data?.map((category) => ({
                  id: category.id,
                  name: category.name,
                }));
                console.log({categoriesData})
                return (
                  <SelectInput
                    id="CategoryId"
                    options={categoriesData}
                    label="صنف المنتج"
                    value={values.CategoryId}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                  ></SelectInput>
                );
              }}
            </Await>
          </Suspense>
          <Suspense fallback={<Skeleton label="اسم الشركة" />}>
            <Await resolve={loadedData?.brand}>
              {({data}) => {
                  const brandData = data?.filter(brand => brand.nameAr).map((brand) => ({
                  id: brand.id,
                  name: brand.nameAr,
                }));
                return (
                  <SelectInput
                    id="BrandId"
                    options={brandData}
                    label="اسم الشركة"
                    value={values.BrandId}
                    setFieldValue={setFieldValue}
                    onChange={handleChange}
                  ></SelectInput>
                );
              }}
            </Await>
          </Suspense>
          <Suspense fallback={<Skeleton label="الألوان" />}>
            <Await resolve={loadedData?.colors}>
              {(result) => {
                const colorData = result?.filter(color => color.nameAr).map((color) => ({
                  id: color.id,
                  name: color.nameAr,
                }));
                return (
                  <MultipleSelect
                    id="ColorsId"
                    options={colorData}
                    label="الألوان"
                    setFieldValue={setFieldValue}
                    value={values.ColorsId}
                    onChange={handleChange}
                  ></MultipleSelect>
                );
              }}
            </Await>
          </Suspense>
          <Suspense fallback={<Skeleton label="القياسات" />}>
            <Await resolve={loadedData?.sizes}>
              {(result) => {
                const sizesData = result?.filter(size => size.nameAr)
                .map((size) => ({

                  id: size.id,
                  name: size.nameAr,
                }));
                console.log({sizesData})
                return (
                  <MultipleSelect
                    id="SizeId"
                    options={sizesData}
                    label="القياسات"
                    setFieldValue={setFieldValue}
                    value={values.SizeId}
                    onChange={handleChange}
                  ></MultipleSelect>
                );
              }}
            </Await>
          </Suspense>

          <Input
            id="Ammount"
            text="الكمية"
            type="number"
            {...getFieldProps("Ammount")}
          />

          <Input
            id="Price"
            text="سعر المنتج"
            type="number"
            {...getFieldProps("Price")}
          />
          <Input
            id="DeliverTime"
            text="وقت التوصيل"
            type="number"
            {...getFieldProps("DeliverTime")}
          />
          <Input
            id="OfferPrice"
            text="سعر المنتج بعد العرض"
            type="number"
            {...getFieldProps("OfferPrice")}
          />
          <Input id="Url" text="رابط" type="text" {...getFieldProps("Url")} />
          <Input
            id="Tags"
            text="أدخل الكلمات المفتاحية"
            type="text"
            {...getFieldProps("Tags")}
          />
          <InputFile
            id="Images"
            text="صور المنتج"
            type="file"
            value={values.Images}
            imagesIds={values.ImagesIds || []}
            setFieldValue={setFieldValue}
            setFiles={setFiles}
            deleteFileFun={deleteProdcutFile}
            files={files}
            ratio={9 / 11}
            multiple
          />
        </Form>
      )}
    </Formik>
  );
};

export const loader = async () => {
  const response = defer({
    colors: await getAllColors(),
    sizes: await getSizes(),
    category: await GetCategories({page:1 , enablePagination:false}),
    brand: await GetBrands(),
  });
  return response;
};
export default AddProduct;





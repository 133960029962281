import React from "react";

const InfoIcon = (props) => {
  return (
    <svg
      width={props.width}
      height={props.width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title />

      <g id="Complete">
        <g id="info-square">
          <g>
            <rect
              data-name="--Rectangle"
              fill="none"
              height="20"
              id="_--Rectangle"
              rx="2"
              ry="2"
              stroke={props.fColor}
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              width="20"
              x="2"
              y="2"
            />

            <line
              fill="none"
              stroke={props.fColor}
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              x1="12"
              x2="12"
              y1="12"
              y2="16"
            />

            <line
              fill="none"
              stroke="#000000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              x1="12"
              x2="12"
              y1="8"
              y2="8"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default InfoIcon;

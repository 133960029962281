import React, { useContext, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { deleteSize, getSizes, useActionSize } from "../../components/api/size";
import AddItem from "../../components/UI/AddItem";
import Spinner from "../../components/UI/Spinner";
import Table from "../../components/UI/Table";
import TableSearch from "../../components/UI/TableSearch";
import { sizesTableHeaders } from "../../constant/tableHeaders";
import ActionContext from "../../context/action-context";
import useForm from "../../hooks/useForm";
// import PaginationContext from "../../context/pagination-context";
const propNames = ["id", "nameAr", "nameEn"];

const Sizes = () => {
  const [searchQuery, setSearchQuery] = useState(""); 
  const { useDeleteSize, useGetSizes } = useActionSize(searchQuery);
  const { data, isLoading } = useGetSizes;
  const { mutate } = useDeleteSize;
  const deleteCTX = useContext(ActionContext);
  // const paginationCtx = useContext(PaginationContext);


  useEffect(() => {
    const savedSearchQuery = localStorage.getItem("searchQuery");
    if (savedSearchQuery) {
      setSearchQuery(savedSearchQuery); 
    }
  }, []); 

useEffect(() => {
  if (searchQuery.trim() !== "") {
    localStorage.setItem("searchQuery", searchQuery); 
  } else {
    localStorage.removeItem("searchQuery");
  }
}, [searchQuery]);



  useEffect(() => {
    if (deleteCTX.id) {
      deleteCTX.setActionFunction(() => mutate);
    }
  }, [deleteCTX.id]);

  const handleSearchChange = (event) => {
    const newSearchQuery = event.target.value;
    setSearchQuery(newSearchQuery);  

  };
 

  return (
    <>
      <AddItem
        url="add-size"
        title="كافة المقاسات"
        buttonName="إضافة مقاس"
        text="في هذا الصفحة تستيطع ايجاد كافة المقاسات"
      />
      {!isLoading && (
        <Table
          currentRouter="size"
          title="جدول المقاسات"
          td={data}
          th={sizesTableHeaders}
          propsTable={propNames}
        >
          <TableSearch 
          onSearchChange={handleSearchChange} 
           searchQuery={searchQuery}
            />
          <></>
        </Table>
      )}{" "}
      {isLoading && <Spinner />}
    </>
  );
};
export const loader = async () => {
  return await getSizes();
};
export default Sizes;




import apiInstance from "./apiInstance";
import API_CONTROLERS from "../../constant/apiControles";
import API_ROUTES from "../../constant/apiEnd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const getAllColors = async () => {
  const response = await apiInstance.get(
    API_CONTROLERS.CP_BASIC + API_ROUTES.COLOR.GET_COLORS
  );
  return response.data.result;
};

export const getColorById = async (id) => {
  const response = apiInstance.get(
    API_CONTROLERS.CP_BASIC + API_ROUTES.COLOR.GET_COLOR_BY_ID,
    {
      params: {
        id,
      },
    }
  );
  return response;
};

export const setColor =  (colorData) => {
  const response = apiInstance.post(
    API_CONTROLERS.COLOR + API_ROUTES.COLOR.SET_COLOR,
    {
      ...colorData,
    }
  );
  return response;
};

export const DeleteColor = async (id) => {
  const response = apiInstance.delete(
    API_CONTROLERS.COLOR + API_ROUTES.COLOR.REMOVE_COLOR,
    {
      params: {
        id,
      },
    }
  );
  return response;
};

export const useActionColor = () => {
  const queryClient = useQueryClient();
  const previousColors = queryClient.getQueryData(["colors"]);
  const { id } = useParams();

  const useGetColors = useQuery({
    queryKey: ["colors"],
    queryFn: getAllColors,
  });

  const useSetColor = useMutation({
    mutationFn: (newColor) => setColor(newColor),
    onMutate: (newColor) => {
      queryClient.setQueryData(["colors"], (oldColors) => [
        ...oldColors,
        newColor,
      ]);
      return () => queryClient.setQueryData(["colors"], previousColors);
    },
    onSuccess: (newColor) => {
      //to assign id for newColor
      const successMessage = "تم" + (id ? " تعديل" : " إضافة") + " اللون بنجاح";

      queryClient.setQueryData(["colors"], (oldColors) => [
        ...oldColors.slice(0, -1),
        newColor.data.result,
      ]);
      queryClient.getDefaultOptions().mutations.onSuccess(successMessage);
    },
    onError: (error, variables, rollback) => {
      rollback();
      queryClient.getDefaultOptions().mutations.onError(error);
    },
  });

  const useDeleteColor = useMutation({
    mutationFn: (id) => DeleteColor(id),
    onMutate: (id) => {
      queryClient.setQueryData(["colors"], (oldColors) =>
        oldColors.filter((oldColor) => oldColor.id !== id)
      );
      return () => queryClient.setQueryData(["colors"], previousColors);
    },
    onSuccess: () =>
      queryClient.getDefaultOptions().mutations.onSuccess("تم حذف اللون بنجاح"),
    onError: (error, v, rollback) => {
      rollback();
      queryClient.getDefaultOptions().mutations.onError(error);
    },
  });

  return {
    useGetColors,
    useSetColor,
    useDeleteColor,
  };
};

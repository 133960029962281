import React from "react";
import "./Button.css";

const Button = (props) => {
  const ButtonClassesNames =
    "button inline-auto " + (props.className ? props.className : "");
  return (
    <button
      {...props}
      aria-label={props.text}
      className={ButtonClassesNames}
      style={{
        "--btn-bg": props.background,
        "--btn-color": props.color,
      }}
    >
      {props.text}
    </button>
  );
};

export default Button;

import React from "react";
import classes from "./Skeleton.module.css";

const Skeleton = (props) => {
  return (
    <div className="grid gap-10" >
      {props.label && <div>{props.label}</div>}
      <div className={classes.skeleton} style={{ ...props["style"] }}>
        <div className={classes["skeleton-highlight"]}></div>
      </div>
    </div>
  );
};

export default Skeleton;

import apiInstance from "./apiInstance";
import API_CONTROLERS from "../../constant/apiControles";
import API_ROUTES from "../../constant/apiEnd";

export const getSettings = async () => {
  const response = apiInstance.get(
    API_CONTROLERS.SETTING + API_ROUTES.SETTING.GET_SETTINGS
  );
  return response;
};

export const setSetting = async (data) => {
  const response = apiInstance.post(
    API_CONTROLERS.SETTING + API_ROUTES.SETTING.SET_SETTING,
    { },{
      params:{
        ...data
      }
    }
  );
  return response;
};

export const getSetting = async (key) => {
  const response = apiInstance.get(
    API_CONTROLERS.SETTING + API_ROUTES.SETTING.GET_SETTINGS
  );
  return response;
};

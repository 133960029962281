import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import API_CONTROLES from "../../constant/apiControles";
import API_ROUTES from "../../constant/apiEnd";
import apiInstance from "./apiInstance";

export const SetAdvertisment = async (advertismentData) => {
  const response = await apiInstance.post(
    API_CONTROLES.ADVERTISMENT + API_ROUTES.ADVERTISMENT.SET_ADVERTISMENT,
    advertismentData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response;
};

export const GetAllAdvertisments = async () => {
  const response = await apiInstance.get(
    API_CONTROLES.ADVERTISMENT + API_ROUTES.ADVERTISMENT.GET_ADVERTISMENT_TYPE
  );

  return response.data.result;
};

export const GetAdvertisment = async (id) => {
  const response = await apiInstance.get(
    API_CONTROLES.ADVERTISMENT + API_ROUTES.ADVERTISMENT.GET_ADVERTISMENT,
    {
      params: {
        id,
      },
    }
  );

  return response;
};

export const RemoveAdvertisment = async (id) => {
  const response = await apiInstance.delete(
    API_CONTROLES.ADVERTISMENT + API_ROUTES.ADVERTISMENT.REMOVE_ADVERTISMENT,
    {
      params: {
        id,
      },
    }
  );

  return response;
};

export const useGetAdvertisments = () => {
  return useQuery(["advertisments"], () => GetAllAdvertisments());
};

export const useSetAdvertisment = () => {
  const queryClient = useQueryClient();
  const previousAdvertisment = queryClient.getQueryData(["advertisments"]);
  const { id } = useParams();

  return useMutation({
    mutationFn: (newAdvertisment) => SetAdvertisment(newAdvertisment),
    onSuccess: async (newAdvertisment) => {
      const successMessage =
        "تم" + (id ? " تعديل" : " إضافة") + " الإعلان بنجاح";
      // queryClient.setQueryData(["advertisments"], (oldAdvertisments) => {
      //   if (previousAdvertisment)
      //     return [...oldAdvertisments, newAdvertisment.data.result];
      //   else return [newAdvertisment.data.result];
      // });
      queryClient.invalidateQueries(["advertisments"]);
      queryClient.getDefaultOptions().mutations.onSuccess(successMessage);
    },
  });
};

export const useDeleteAdvertisment = () => {
  const queryClient = useQueryClient();
  const previousAdvertisment = queryClient.getQueryData(["advertisments"]);

  return useMutation({
    mutationFn: (id) => RemoveAdvertisment(id),
    onMutate: (id) => {
      queryClient.setQueryData(["advertisments"], (oldAdvertisments) =>
        oldAdvertisments.filter((oldAdvertisment) => oldAdvertisment.id !== id)
      );
      return () =>
        queryClient.setQueryData(["advertisments"], previousAdvertisment);
    },
    onSuccess: () =>
      queryClient
        .getDefaultOptions()
        .mutations.onSuccess("تم حذف الإعلان بنجاح"),
    onError: (error, variables, rollback) => {
      rollback();
      queryClient.getDefaultOptions().mutations.onError(error);
    },
  });
};

import { motion } from "framer-motion";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { base64ImageToBlob } from "../../helpers/helper";
import ImageEditor from "../imgEditor/ImgEditor";
import "./Input.css";
import ImageResizer from "react-image-file-resizer";

const InputFile = (props) => {
  const InputRef = useRef();
  const [showImgEditor, setShowImgEditor] = useState(false);
  const [editingImg, setEditingImg] = useState([]);

  const labelVaraints = {
    hover: {
      scale: 1.1,
    },
  };
  const plusVaraints = {
    hover: {
      scale: 1.3,
      rotate: "90deg",
    },
  };

  const convertToJPEG = (originalImage) => {
    try {
      // Convert image to JPEG
      ImageResizer.imageFileResizer(
        originalImage,
        300, // New width (adjust as needed)
        300, // New height (adjust as needed)
        "JPEG", // Output format
        100, // Quality (0-100)
        0, // Rotation (0 = no rotation)
        (uri) => {
          // The resized JPEG image URI
          const imageFile = new File([uri], "resized_image.jpg", {
            type: "image/jpeg",
          });
          originalImage = imageFile;
        },
        "base64" // Output type: base64 or blob (base64 will give you the image data as a base64 string)
      );
    } catch (err) {
      console.error("Error converting image:", err);
    }
  };

  const converToFile = (editedImg) => {
    const blob = base64ImageToBlob(editedImg.imageBase64);
    let file = new File([blob], editedImg.fullName, {
      type: editedImg.mimeType,
    });

    return file;
  };

console.log({editingImg})
  const changeInputHandler = useCallback(() => {
    if (InputRef.current?.files[0]) {
      let file = InputRef.current?.files[0];
      convertToJPEG(InputRef.current.files[0]);
      const url = URL.createObjectURL(file);
      console.log({ file })
      setEditingImg(url);
      setShowImgEditor(true);
    }
    InputRef.current.value = "";
  }, []);

  const saveImageHandler = (editedImg) => {
    addNewValueToArray(editedImg.imageBase64, converToFile(editedImg));
    setShowImgEditor(false);
    setEditingImg([]);
  };

  const addNewValueToArray = (imgUrl, newFile) => {
    props.setFieldValue(props.id, [...props.value, imgUrl]);
    props.setFiles((prevFiles) => [...prevFiles, newFile]);
  };

  const removeImgHandler = (imgIndex) => {
    props.setFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== imgIndex)
    );
    props.setFieldValue(
      props.id,
      props.value.filter((_, index) => index !== imgIndex)
    );
    if (props.multiple && props.imagesIds) {
      props.deleteFileFun(props.imagesIds[imgIndex]);
      props.imagesIds.filter((id) => id !== imgIndex);
    }
  };

  const closeImgEditor = useCallback(() => {
    setShowImgEditor(false);
    InputRef.current.value = "";
  }, []);

  return (
    <div className="control grid gap-10">
      {props.text}
      {/* {isLoading && (
        <div className="flex gap-10">
            {props.value.map((img)=> (
           <Skeleton key={img} style={{ width: "50px" }} />
          ))}
        </div>
      )} */}
      <div className="flex gap-10 wrap">
        {props.value?.lenght !== 0 &&
          props.value?.map((img, index) => (
            <label
              id={index}
              key={index}
              data-type={props.type}
              className={"filled"}
            >
              {<img src={img} alt={props.text} />}
              <span
                className="delete place-center"
                onClick={removeImgHandler.bind({}, index)}
              >
                حذف
              </span>
            </label>
          ))}
        {(props.multiple || props.value?.length === 0) && (
          <motion.label
            htmlFor={props.id}
            data-type={props.type}
            variants={labelVaraints}
            whileHover={"hover"}
          >
            
            {InputRef.current?.value.lenght === 0 ? (
              <img src={""} alt={props.text} />
            ) : (
              <motion.div variants={plusVaraints}>+</motion.div>
            )}
          </motion.label>
        )}
      </div>
      <input
        onChange={changeInputHandler}
        name={props.id}
        placeholder={props.placeholder ? props.placeholder : ""}
        aira-label={props.id}
        ref={InputRef}
        id={props.id}
        type={props.type}
      />
      {showImgEditor && (
        <ImageEditor
          imageUrl={editingImg}
          closeHandler={closeImgEditor}
          isImgEditorShown={showImgEditor}
          onSave={saveImageHandler}
          ratio={props.ratio}
        />
      )}
    </div>
  );
};

export default InputFile;

// <ImageEditor
//   imageUrl={editingImg}
//   isImgEditorShown={showImgEditor}
//   setIsImgEditorShown={setShowImgEditor}
//   ratio={props.ratio}
//   onSave={(imagePromisse) => {
//     imagePromisse.then((image) => {
//       setShowImgEditor(false);
//       saveImageHandler(image);
//     });
//   }}
//   containerStyle={{
//     position: "relative",
//     width: "100%",
//     height: 300,
//     background: "#333",
//   }}
// />
